
/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect } from "react";

import { AppInterface, AppConfigFeatures } from '@aws-prototype/shared';

// polaris
import { SpaceBetween, Spinner } from "@amzn/awsui-components-react";

// navigation
import { useParams } from "react-router-dom";

// React query 
import { useClaimBR } from '../../../hooks/useClaim';

// redux
import { useDispatch } from 'react-redux'

// components import 
import DocumentStatus from './DocumentStatus';
import BusinessRules from './BusinessRules';

interface ReviewProps {
    useCase: string
}

const Review = (props: ReviewProps) => {
    const dispatch = useDispatch();

    // params
    const { claimid, appid } = useParams();

    // RQ Fetch
    const { data } = useClaimBR(claimid! || appid!);
  
//console.log(appid);

    useEffect(() => {
        dispatch(AppConfigFeatures.setHelpKey(6));
    }, [dispatch])

    return (
        <SpaceBetween size="l">
            {
                (props.useCase === "claim")?
                <>
                    <DocumentStatus useCase={props.useCase}/>
                    {data ?
                        <BusinessRules
                            tableTitle="Business Rules: Key Fields Extracted With Confidence" showConfidence
                            items={data.filter((item: AppInterface.RuleResult) => item.documents && item.documents.length === 1)} /> : <Spinner />}
                    {data ?
                        <BusinessRules
                            tableTitle="Business Rules: Cross Document Verification"
                            items={data.filter((item: AppInterface.RuleResult) => item.documents && item.documents.length > 1 && !item.ruleDescription.includes('ICD10'))} /> : <Spinner />}
                    {data ?
                        <BusinessRules
                            tableTitle="Business Rules: ICD10-CM Medical Codes Verification"
                            items={data.filter((item: AppInterface.RuleResult) => item.documents && item.ruleDescription.includes('ICD10'))} /> : <Spinner />}
                </>
                :
                <>
                    <DocumentStatus useCase={props.useCase}/>
                    {data ?
                        <BusinessRules
                            tableTitle="Business Rules: Key Fields Extracted With Confidence" showConfidence
                            items={data.filter((item: AppInterface.RuleResult) => item.documents && item.documents.length === 1)} /> : <Spinner />}
                    {data ?
                        <BusinessRules
                            tableTitle="Business Rules: Cross Document Verification"
                            items={data.filter((item: AppInterface.RuleResult) => item.documents && item.documents.length > 1)} /> : <Spinner />}
                </>
            }
            
        </SpaceBetween>
    )
}

export default Review;