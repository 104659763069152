/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect, useState } from "react";
import { AppEnums, AppInterface } from '@aws-prototype/shared';

// React Query
import { useQueryClient, } from "react-query";
import { useClaimAnalyzeDoc } from '../../../../hooks/useClaim';

//polaris
import { Table, } from "@amzn/awsui-components-react/polaris";
 
 type docTypesEnumType = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.HealthcareDocTypes | AppEnums.ApplicationDocTypes

interface TableProps {
    // docType: AppEnums.ClaimDocTypes;
    docType: docTypesEnumType;
}

interface DataItem {
    [key: string]: string,

}
interface TableDataProps {
    header: Array<string>,
    data: Array<any>
}


const Tables = (props: TableProps) => {
    //props
    const { docType } = props;
    const queryClient = useQueryClient();    

    const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as Array<AppInterface.ClaimDoc>)
        .find(item => item.documentType.Name === docType);
    
    (docData)&&console.log(docData['textractDocumentAnalysisOutput']);
    // RQ Fetch
    const { data } = useClaimAnalyzeDoc(docData!.hashKey, docData!.sortKey, AppEnums.DocumentOutput.TABLES);

    // states
    const [tableData, setTableData] = useState<TableDataProps>();


    useEffect(() => {
        if (data) {
            // Get headers
            const headerData = data.filter((h: any) => h.rowIndex === 2 && h.text.length > 0);

            const filteredData = data.filter((f: any) => f.rowIndex > 2 && f.text.length > 0);
            // extract table data
            const tableObject = filteredData.filter((fData: any) => (fData.rowIndex > 2)).reduce(
                (acc: any, currentItem: any) => {
                    const headerItem = headerData.find((hData: any) => (hData.columnIndex === currentItem.columnIndex) && (hData.tableIndex === currentItem.tableIndex));
                    if (headerItem && currentItem.text.length > 0) {
                        acc.data[currentItem.rowIndex] = {
                            ...acc.data[currentItem.rowIndex],
                            [headerItem.text]: currentItem.text,
                        }
                    }
                    return acc;
                }, {
                data: {} as DataItem,
            });

            setTableData({
                header: headerData.map((item: any) => item.text),
                data: Object.keys(tableObject.data).map((key: any) => tableObject.data[key])
            });
        }
    }, [data])

    return (
        <div style={{ height: window.innerHeight * 0.8, overflow: 'auto', }}>
            {tableData &&
                <Table
                    variant="embedded"
                    columnDefinitions={
                        Array.from(tableData.header).map((headerItem: string) => ({
                            id: headerItem,
                            header: headerItem,
                            cell: item => item[headerItem],
                        }))
                    }
                    items={tableData.data}
                />}
        </div>

    )
}

export default Tables; 