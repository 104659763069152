/**********************************************************************************************************************
 *  Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Point } from '../data-access/interfaces';

export interface AnnotationsInterface {
  [key: string]: {
    points: Array<Point[]>;
    show: boolean;
  };
}

interface AnnotationsActions {
  type: string;
  polygons: Array<Point[]>;
}

// Define the initial state using that type
const initialState: AnnotationsInterface = {
  pii: {
    points: [],
    show: false,
  },
};

const featureSlice = createSlice({
  name: 'annotationFeature',
  initialState: initialState,
  reducers: {
    setAnnotations: (state, action: PayloadAction<AnnotationsActions>) => {
      state[action.payload.type].points = action.payload.polygons;
    },
    showAnnotations: (state, action: PayloadAction<string>) => {
      state[action.payload].show = true;
    },
    hideAnnotations: (state, action: PayloadAction<string>) => {
      state[action.payload].show = false;
    },
    toggleAnnotations: (state, action: PayloadAction<string>) => {
      state[action.payload].show = !state[action.payload].show;
    },
  },
});

export default featureSlice.reducer;
export const { setAnnotations, showAnnotations, hideAnnotations, toggleAnnotations } = featureSlice.actions;
