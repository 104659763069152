/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect, useState } from "react";
import { AppEnums, AppInterface } from '@aws-prototype/shared';

//polaris
import { TextContent, SpaceBetween } from "@amzn/awsui-components-react/polaris";

// React Query
import { useQueryClient, } from "react-query";

// utils
import { getDocumentAsJSON } from '../../../../utils';

// components import 
import KeyPair from '../../KeyPair';
 
 type docTypesEnumType = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.HealthcareDocTypes | AppEnums.ApplicationDocTypes;

interface AnalyzeIDProps {
    // docType: AppEnums.ClaimDocTypes;
    docType: docTypesEnumType;
}

const ANALYZE_ID_KEY = 'textractAnalyzeIDOutput';

const AnalyzeID = (props: AnalyzeIDProps) => {
    //props
    const { docType } = props;
    const queryClient = useQueryClient();

    // states
    const [analyzeIdJson, setAnalyzeIdJson] = useState<AppInterface.KeyValue[]>([]);

    // retrieve data from RQ cache
    const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as
        Array<AppInterface.ClaimDoc>).find(item => item.documentType.Name === docType);



    useEffect(() => {
        const getAnalyzeIdDoc = async () => {
            const analyzeIdResponse: any = await getDocumentAsJSON(docData![ANALYZE_ID_KEY]!);
            setAnalyzeIdJson(analyzeIdResponse.IdentityDocuments[0].IdentityDocumentFields.filter((field: any) => field.Type.Text !== 'STATE_NAME').map((item: any) => ({
                key: item.Type.Text,
                value: item.ValueDetection.Text.length === 0 ? '-' : item.ValueDetection.Text,
                confidence: `${item.ValueDetection.Confidence.toFixed(2)}%`,
            })));
        }
        if (docType && docData && docData.documentType.Name === docType) {
            getAnalyzeIdDoc();
        }
    }, [docData, docType]);

    return (
        <div style={{ display: 'flex', padding: 10, height: window.innerHeight * 0.8, overflow: 'auto', }}>
            <SpaceBetween size="l">
                <KeyPair data={analyzeIdJson} form />
                <TextContent>
                    <p><b>Note:</b> some of the items have been filtered for the demo</p>
                </TextContent>
            </SpaceBetween>
        </div>
    )
}

export default AnalyzeID;
