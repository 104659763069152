/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
// Polaris Components
import {
    AppLayout,
    Wizard,
    WizardProps,
    Alert,
    Spinner,
    Header,
    BreadcrumbGroup,
    Badge,
    BreadcrumbGroupProps,
    Box
} from '@amzn/awsui-components-react';

// navigation
import { useNavigate, useParams } from "react-router-dom";
import { routeNames, routes } from '../../common/route';

// React query 
import { useClaimDocs } from '../../hooks/useClaim';
import { useWindowDimensions } from '../../hooks/useWindowDiemnsions';

// Components
import Info from '../components/Info';
import ClaimDocuments from '../components/ClaimDocuments';
import DocumentClassify from '../components/classify/DocumentClassify';
import DocumentExtract from '../components/extract/DocumentExtract';
import ExtractEntity from '../components/entity-extract/ExtractEntity';
import Enrich from '../components/enrich/Enrich';
import Review from '../components/review/ClaimReview';


const steps: WizardProps.Step[] = [
    {
        title: 'Document Capture',
        info: <Info helpKey={1} />,
        content: <ClaimDocuments useCase='mortgage' />,
        description: "List of  unlabeled documents uploaded as a package by borrower for underwriter processing.",
    },
    {
        title: 'Document Classification',
        info: <Info helpKey={2} />,
        content: <DocumentClassify useCase='mortgage' />,
        description: "Labeled accurately categorized documents for underwriter processing with options for human override.",
    },
    {
        title: 'Document Extraction',
        info: <Info helpKey={3} />,
        content: <DocumentExtract useCase='mortgage' />,
        description: "Accurate extraction of documents for underwriter loan processing.",
    },
    {
        title: 'Document Extraction Continued',
        info: <Info helpKey={4} />,
        content: <ExtractEntity useCase='mortgage' />,
        description: "Accurate elements extraction from documents for underwriter loan processing.",
    },
    {
        title: 'Document Enrichment',
        info: <Info helpKey={5} />,
        content: <Enrich useCase='mortgage' />,
        description: "Enrich documents by extracting key information.",
    },
    {
        title: 'Document Review & Verification',
        info: <Info helpKey={6} />,
        content: <Review useCase='mortgage' />,
        description: "Review documents and verify extracted features and their associated values.",
    },
];

const i18nStrings: WizardProps.I18nStrings = {
    stepNumberLabel: stepNumber => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
    cancelButton: 'Cancel',
    previousButton: 'Previous',
    nextButton: 'Next',
    submitButton: 'Send to Loan Processing System',
    optional: 'optional',
    skipToButtonLabel: (step) =>
        `Skip to ${step.title}`,
};

const AnalyzeMortgage = () => {
    const navigate = useNavigate();
    // params
    const { appid } = useParams();
    const { width } = useWindowDimensions();

    // RQ Fetch
    const { isError, isFetched, isFetching } = useClaimDocs(appid!);

    const gotoPage = (event: any) => {
        event.preventDefault();
        navigate(event.detail.href)
    }

    const WizHeader = () => {
        return <>
            <Header variant="h1">Mortgage - Loan processor portal</Header>
            <Badge color="blue">Mortgage Application- {appid}</Badge>
        </>
    }

    const BCrumb = () => {
        return (<BreadcrumbGroup
            items={[
                { text: "Home", href: "/" },
                { text: "Mortgage Applications", href: "/mortgage" },
                { text: "Application packet", href: "#components" }
            ]}
            ariaLabel="Breadcrumbs"
            expandAriaLabel="Show path"
            onFollow={(event: CustomEvent<BreadcrumbGroupProps.ClickDetail>) => gotoPage(event)}
        />)
    }

    if (isFetching) {
        return <AppLayout
            contentType='wizard'
            minContentWidth={width - 300}
            //disableContentPaddings
            navigationHide={true}
            toolsHide={true}
            breadcrumbs={<BCrumb />}
            disableContentHeaderOverlap
            content={
                <Box textAlign="center" variant="p">
                    <Spinner size="large" />
                </Box>
            }
            contentHeader={<WizHeader />}
        />
    }

    return (
        <>
            <AppLayout
                contentType='wizard'
                minContentWidth={width - 300}
                // disableContentPaddings
                navigationHide={true}
                toolsHide={true}
                breadcrumbs={<BCrumb />}
                content={
                    <>
                        {
                            (isError) &&
                            <Alert type='error' header="Unable to fetch Claim Documents">
                                We are unable to fetch the list of claim documents at this time. Please try again later.
                            </Alert>
                        }
                        {
                            (isFetched) &&
                            <Wizard steps={steps}
                                i18nStrings={i18nStrings}
                                onNavigate={({ detail }) =>
                                    console.log("Wizard next step: ", detail)
                                }
                                allowSkipTo
                                onCancel={() => navigate(routes[routeNames.Mortgage].href)}
                                onSubmit={() => navigate(routes[routeNames.Home].href)}
                            />
                        }
                    </>
                }
                contentHeader={<WizHeader />}
            />
        </>
    )
}

export default AnalyzeMortgage;