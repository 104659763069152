/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useState, useEffect } from 'react'
import { StatusIndicator, Popover, Spinner } from "@amzn/awsui-components-react/polaris";

import { AppEnums, AppInterface } from '@aws-prototype/shared';

// data
import { CLAIM_DOCS_STATUS_MESSAGE, } from '../../../data/Claims';

interface DocumentClassificationStatusProps {
    confidence: number
}

const DocumentClassificationStatus = (props: DocumentClassificationStatusProps) => {
    const { confidence } = props;

    const [statusMessage, setStatusMessage] = useState<AppInterface.ClaimDocStatus | null>(null);

    useEffect(() => {
        if (confidence >= 0.8) {
            setStatusMessage(CLAIM_DOCS_STATUS_MESSAGE.find(item => item.status === AppEnums.Status.SUCCESS)!)
        } else if (confidence === 0) {
            setStatusMessage(CLAIM_DOCS_STATUS_MESSAGE.find(item => item.status === AppEnums.Status.INFO)!)
        }
        else {
            setStatusMessage(CLAIM_DOCS_STATUS_MESSAGE.find(item => item.status === AppEnums.Status.WARNING)!)
        }
    }, [confidence]);

    return (
        <>
            {statusMessage ?
                <Popover
                    dismissAriaLabel="Close"
                    header={statusMessage.header}
                    content={statusMessage.content}
                >
                    <StatusIndicator type={statusMessage.status}>{statusMessage.header}</StatusIndicator>
                </Popover> :
                <Spinner />
            }
        </>
    )
}


export default DocumentClassificationStatus;