/**********************************************************************************************************************
 *  Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// shared
import { ClaimDocTypes, MortgageDocTypes, HealthcareDocTypes, ApplicationDocTypes } from '../data-access/enums';

const ZOOM_PRESET = {
  in: 0.02,
  out: 0.02,
  max: 2,
  min: 0.02,
  defaults: {
    [ClaimDocTypes.CMS1500 as string]: {
      scale: 0.22,
    },
    [ClaimDocTypes.DISCHARGE_SUMMARY as string]: {
      scale: 0.22,
    },
    [ClaimDocTypes.MEDICAL_TRANSCRIPTION as string]: {
      scale: 0.42,
    },
    [ClaimDocTypes.LICENSE as string]: {
      scale: 0.22,
    },
    [ClaimDocTypes.INSURANCE_ID as string]: {
      scale: 0.22,
    },
    [ClaimDocTypes.INVOICE_RECEIPT as string]: {
      scale: 0.43,
    },

    [MortgageDocTypes.URLA1003 as string]: {
      scale: 0.22,
    },
    [MortgageDocTypes.W2 as string]: {
      scale: 0.43,
    },
    [MortgageDocTypes.PAY_STUB as string]: {
      scale: 0.41,
    },
    [MortgageDocTypes.PASSPORT as string]: {
      scale: 0.26,
    },
    [MortgageDocTypes.MORTGAGE_NOTE as string]: {
      scale: 0.22,
    },
    [MortgageDocTypes.BANK_STMT as string]: {
      scale: 0.43,
    },
    [MortgageDocTypes.HOA_STMT as string]: {
      scale: 0.22,
    },
    [HealthcareDocTypes.PRESCRIPTION as string]: {
      scale: 0.18,
    },
    [ApplicationDocTypes.APPLICATION_FORM as string]: {
      scale: 0.18,
    },
    [ApplicationDocTypes.BANK_STMT as string]: {
      scale: 0.18,
    },
    [ApplicationDocTypes.UTILITY_BILL as string]: {
      scale: 0.18,
    },
    [ApplicationDocTypes.SSN_CARD as string]: {
      scale: 0.5,
    },
  },
};

interface Coordinates {
  docType: string;
  x: number;
  y: number;
}

export interface ExtractSliceInterface extends Coordinates {
  scale: number;
  selectedTab?: string;
}

// Define the initial state using that type
const initialState: ExtractSliceInterface[] = [
  {
    docType: ClaimDocTypes.CMS1500,
    scale: ZOOM_PRESET.defaults[ClaimDocTypes.CMS1500].scale,
    x: 10,
    y: 10,
  },
  {
    docType: ClaimDocTypes.DISCHARGE_SUMMARY,
    scale: ZOOM_PRESET.defaults[ClaimDocTypes.DISCHARGE_SUMMARY].scale,
    x: 10,
    y: 10,
  },
  {
    docType: ClaimDocTypes.MEDICAL_TRANSCRIPTION,
    scale: ZOOM_PRESET.defaults[ClaimDocTypes.MEDICAL_TRANSCRIPTION].scale,
    x: 10,
    y: 10,
  },
  {
    docType: ClaimDocTypes.LICENSE,
    scale: ZOOM_PRESET.defaults[ClaimDocTypes.LICENSE].scale,
    x: 10,
    y: 10,
  },
  {
    docType: ClaimDocTypes.INSURANCE_ID,
    scale: ZOOM_PRESET.defaults[ClaimDocTypes.INSURANCE_ID].scale,
    x: 10,
    y: 10,
  },
  {
    docType: ClaimDocTypes.INVOICE_RECEIPT,
    scale: ZOOM_PRESET.defaults[ClaimDocTypes.INVOICE_RECEIPT].scale,
    x: 10,
    y: 10,
  },

  {
    docType: MortgageDocTypes.URLA1003,
    scale: ZOOM_PRESET.defaults[MortgageDocTypes.URLA1003].scale,
    x: 10,
    y: 10,
  },
  {
    docType: MortgageDocTypes.W2,
    scale: ZOOM_PRESET.defaults[MortgageDocTypes.W2].scale,
    x: 10,
    y: 10,
  },
  {
    docType: MortgageDocTypes.PAY_STUB,
    scale: ZOOM_PRESET.defaults[MortgageDocTypes.PAY_STUB].scale,
    x: 10,
    y: 10,
  },
  {
    docType: MortgageDocTypes.PASSPORT,
    scale: ZOOM_PRESET.defaults[MortgageDocTypes.PASSPORT].scale,
    x: 10,
    y: 10,
  },
  {
    docType: MortgageDocTypes.MORTGAGE_NOTE,
    scale: ZOOM_PRESET.defaults[MortgageDocTypes.MORTGAGE_NOTE].scale,
    x: 10,
    y: 10,
  },
  {
    docType: MortgageDocTypes.BANK_STMT,
    scale: ZOOM_PRESET.defaults[MortgageDocTypes.BANK_STMT].scale,
    x: 10,
    y: 10,
  },
  {
    docType: MortgageDocTypes.HOA_STMT,
    scale: ZOOM_PRESET.defaults[MortgageDocTypes.HOA_STMT].scale,
    x: 10,
    y: 10,
  },
  {
    docType: HealthcareDocTypes.PRESCRIPTION,
    scale: ZOOM_PRESET.defaults[HealthcareDocTypes.PRESCRIPTION].scale,
    x: 10,
    y: 10,
  },
  {
    docType: ApplicationDocTypes.APPLICATION_FORM,
    scale: ZOOM_PRESET.defaults[ApplicationDocTypes.APPLICATION_FORM].scale,
    x: 10,
    y: 10,
  },
  {
    docType: ApplicationDocTypes.SSN_CARD,
    scale: ZOOM_PRESET.defaults[ApplicationDocTypes.SSN_CARD].scale,
    x: 10,
    y: 10,
  },
  {
    docType: ApplicationDocTypes.UTILITY_BILL,
    scale: ZOOM_PRESET.defaults[ApplicationDocTypes.UTILITY_BILL].scale,
    x: 10,
    y: 10,
  },
];

const getIndex = (state: ExtractSliceInterface[], docType: string) => {
  return state.findIndex((item) => item.docType === docType);
};

const extractSlice = createSlice({
  name: 'extractFeatures',
  initialState: initialState,
  reducers: {
    zoomIn: (state, action: PayloadAction<string>) => {
      const stateIndex = getIndex(state, action.payload);
      if (
        stateIndex > -1 &&
        state[stateIndex].scale <= ZOOM_PRESET.max &&
        state[stateIndex].scale + ZOOM_PRESET.in <= ZOOM_PRESET.max
      ) {
        state[stateIndex].scale += ZOOM_PRESET.in;
      }
    },
    zoomOut: (state, action: PayloadAction<string>) => {
      const stateIndex = getIndex(state, action.payload);
      if (
        stateIndex > -1 &&
        state[stateIndex].scale >= ZOOM_PRESET.min &&
        state[stateIndex].scale + ZOOM_PRESET.out >= ZOOM_PRESET.min
      ) {
        state[stateIndex].scale -= ZOOM_PRESET.out;
      }
    },
    zoomReset: (state, action: PayloadAction<string>) => {
      const stateIndex = getIndex(state, action.payload);
      if (stateIndex > -1) {
        state[stateIndex].scale = ZOOM_PRESET.defaults[state[stateIndex].docType].scale;
        state[stateIndex].x = 10;
        state[stateIndex].y = 10;
      }
    },
    onDrag: (state, action: PayloadAction<Coordinates>) => {
      const stateIndex = getIndex(state, action.payload.docType);
      if (stateIndex > -1) {
        state[stateIndex].x = action.payload.x;
        state[stateIndex].y = action.payload.y;
      }
    },
    setSelectedTab: (state, action: PayloadAction<{ doctype: string; activeTabId: string }>) => {
      const stateIndex = getIndex(state, action.payload.doctype);
      if (stateIndex > -1) {
        state[stateIndex].selectedTab = action.payload.activeTabId;
      }
    },
  },
});

export default extractSlice.reducer;
export const { zoomIn, zoomOut, zoomReset, onDrag, setSelectedTab } = extractSlice.actions;
