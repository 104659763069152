/** *******************************************************************************************************************
*  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
*                                                                                                                    *
*  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
*  with the License. A copy of the License is located at                                                             *
*                                                                                                                    *
*      http://aws.amazon.com/asl/                                                                                    *
*                                                                                                                    *
*  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
*  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
*  and limitations under the License.                                                                                *
******************************************************************************************************************** */
import { AppInterface } from '@aws-prototype/shared';

//polaris
import { Box, ColumnLayout, Textarea } from "@amzn/awsui-components-react/polaris";

interface KeyValueProps {
    data: Array<AppInterface.KeyValue>
    form?: boolean
}


// variant="awsui-key-label"
const KeyPair = (props: KeyValueProps) => {

    const { data, form } = props;

    return (
        <ColumnLayout columns={2}>
            {data.map((item, index: number) => (
                <div key={`${item.key}-${index}`}
                    style={{
                        borderRadius: 8,
                        borderColor: 'red',
                        borderWidth: 2,
                    }}>
                    <Box color="inherit" float="left">
                        <Box textAlign="left" variant={form ? "strong" : "awsui-key-label"} >
                            {item.key}
                        </Box>
                        {form ? <Textarea

                            value={item.value}
                            disabled
                            rows={2}
                        /> : <Box textAlign="left" variant="strong">
                            {item.value}
                        </Box>}
                    </Box>
                </div>
            ))}
        </ColumnLayout>
    )
}

export default KeyPair;