/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { HelpPanel, Icon } from "@amzn/awsui-components-react";

const DocumentClassificationHelp = () => {
    return (
        <HelpPanel
            footer={
                <div>
                    <h3>
                        Learn more <Icon name="external" />
                    </h3>
                    <ul>
                        <li>
                            <a target="_blank" rel="noopener noreferrer nofollow" href="https://docs.aws.amazon.com/comprehend/latest/dg/how-document-classification.html">Custom Classification</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noopener noreferrer nofollow" href="https://catalog.us-east-1.prod.workshops.aws/workshops/c2af04b2-54ab-4b3d-be73-c7dd39074b20/en-US/02-getting-started/module-1-doc-classification">Document Classification</a>
                        </li>

                    </ul>
                </div>
            }
            header={<h2>Identify Document Types</h2>}
        >
            <div>
                Sort different types of
                documents into the
                right category with
                Amazon Comprehend
                Custom Classifier,
                and send them to the
                correct document
                pipeline.
                Amazon Comprehend
                to build your own
                models for custom
                classification. You can
                also assign a
                document to a specific
                class or category, or
                to multiple ones.
            </div>
        </HelpPanel>)
}
export default DocumentClassificationHelp;