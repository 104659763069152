/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect } from 'react';

// shared imports
import { AppEnums, AppConfigFeatures } from '@aws-prototype/shared';

// redux
import { useDispatch } from 'react-redux'

// components
import ExtractFeature from '../extract/ExtractFeature';
import HumanReview from '../HumanReview';
import ICD from './ICD';
import PII from './PII';
import RxNorm from './RxNorm';
import MedicalConditions from './MedicalConditions';
import SnomedCodes from './SnomedCodes';
import { Summary } from '../DocumentSummaries';

export const extractPageConfig = [
    {
        docType: AppEnums.ClaimDocTypes.MEDICAL_TRANSCRIPTION,
        tabs: [
            {
                label: "ICD-10-CM Concepts",
                id: "icd10",
                content: <ICD docType={AppEnums.ClaimDocTypes.MEDICAL_TRANSCRIPTION} />,
            },
            {
                label: "PII/PHI",
                id: "pii",
                content: <PII docType={AppEnums.ClaimDocTypes.MEDICAL_TRANSCRIPTION} />,
            },
        ],
    },

];

export const extractMortgagePageConfig = [
    {
        docType: AppEnums.MortgageDocTypes.PAY_STUB,
        tabs: [
            {
                label: "PII",
                id: "pii",
                content: <PII docType={AppEnums.MortgageDocTypes.PAY_STUB} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },

];


export const extractHealthcarePageConfig = [
    {
        docType: AppEnums.HealthcareDocTypes.MEDICAL_TRANSCRIPTION,
        tabs: [
            {
                label: "Medical Conditions",
                id: "conditions",
                content: <MedicalConditions docType={AppEnums.HealthcareDocTypes.MEDICAL_TRANSCRIPTION} />,
            },            
            {
                label: "ICD-10-CM Concepts",
                id: "icd10",
                content: <ICD docType={AppEnums.HealthcareDocTypes.MEDICAL_TRANSCRIPTION} />,
            },
            {
                label: "SNOMED Concepts",
                id: "snomed",
                content: <SnomedCodes docType={AppEnums.HealthcareDocTypes.MEDICAL_TRANSCRIPTION} />,
            },
            {
                label: "PII/PHI",
                id: "pii",
                content: <PII docType={AppEnums.HealthcareDocTypes.MEDICAL_TRANSCRIPTION} />,
            },
        ],
    },
    {
        docType: AppEnums.HealthcareDocTypes.PRESCRIPTION,
        tabs: [
            {
                label: "RxNorm Concepts",
                id: "RxNorm",
                content: <RxNorm docType={AppEnums.HealthcareDocTypes.PRESCRIPTION} />,
            },
        ],
    },

];


export const extractPublicSectorPageConfig = [
    // { // todo: add pii stuff here todo: pii
    //     docType: AppEnums.ApplicationDocTypes.APPLICATION_FORM,
    //     tabs: [
    //         {
    //             label: "PII/PHI",
    //             id: "pii",
    //             content: <PII docType={AppEnums.ApplicationDocTypes.APPLICATION_FORM} />,
    //         },
    //         {
    //             label: "Summary",
    //             id: "summary",
    //             content: <Summary model1Summary={undefined} model2Summary={undefined} docType={AppEnums.ApplicationDocTypes.APPLICATION_FORM} />,
    //         },
    //     ],
    // },
    { // todo: add pii stuff here todo: pii
        docType: AppEnums.ApplicationDocTypes.SSN_CARD,
        tabs: [
            {
                label: "PII/PHI",
                id: "pii",
                content: <PII docType={AppEnums.ApplicationDocTypes.SSN_CARD} />,
            },
            {
                label: "Summary",
                id: "summary",
                content: <Summary model1Summary={undefined} model2Summary={undefined} docType={AppEnums.ApplicationDocTypes.SSN_CARD} />,
            },
        ],
    }

];

interface EnrichProps {
    useCase: string
} 

const Enrich = (props: EnrichProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AppConfigFeatures.setHelpKey(5));
    }, [dispatch])

    return (
        <>
            {
                (props.useCase === 'claim')?
                    extractPageConfig.map(feature =>
                        <ExtractFeature
                            key={feature.docType}
                            docType={feature.docType}
                            tabs={feature.tabs} />
                    )
                :null
            }
            {
                (props.useCase === 'mortgage')?
                extractMortgagePageConfig.map(feature =>
                    <ExtractFeature
                        key={feature.docType}
                        docType={feature.docType}
                        tabs={feature.tabs} />
                )
                :null               
            }
            {
                (props.useCase === 'healthcare')?
                    extractHealthcarePageConfig.map(feature =>
                        <ExtractFeature
                            key={feature.docType}
                            docType={feature.docType}
                            tabs={feature.tabs} />
                    )
                :null
            }                
            {
                (props.useCase === 'publicsector')?
                extractPublicSectorPageConfig.map(feature =>
                    <ExtractFeature
                        key={feature.docType}
                        docType={feature.docType}
                        tabs={feature.tabs} />
                )
                :null               
            }


        </>
    )
}

export default Enrich;
