/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
// Polaris Components
import {
  Container,
  Header,
  ColumnLayout,
  Link,
  Box,
  AppLayout,
  BreadcrumbGroup,
  BreadcrumbGroupProps,
  SpaceBetween,
  PieChart,
  Select,
  FormField,
  Spinner
} from '@amzn/awsui-components-react';

import { useWindowDimensions } from '../../hooks/useWindowDiemnsions';
// router
import { useNavigate } from "react-router-dom";

// React query  
import { useClaimDocs } from '../../hooks/useClaim';
import { useEffect } from 'react';
import { averageTimePerDocumentAtom, numberOfDocumentsAtom, 
  averageConfidenceAtom, numberDocsManualRequiredAtom, 
  averageSummary1PercentageAtom, averageSummary2PercentageAtom, 
  pieChartDataAtom, batchIdAtom, timeSeriesDataAtom, startDateAtom, endDateAtom, selectedTimeFrameAtom } from '@aws-prototype/shared';
import { useAtom, atom } from 'jotai';
import { ClaimDoc } from '@aws-prototype/shared/dist/libs/data-access/interfaces';
import TimeSeriesReporting from '../components/reporting/TimeSeriesReporting'

const calculateProcessingTime = (startTime: string, endTime: string) => {
  const endTimeDate = new Date(endTime)
  const startTimeDate = new Date(startTime)
  const msBetweenDates = endTimeDate.getTime() - startTimeDate.getTime()
  return ((msBetweenDates/1000)/60)
}

const getAverage = (desList: number[]) => {
  const sum = desList.reduce((a, b) => a + b, 0);
  return (Math.round(100* sum / desList.length) / 100) || 0;
}

const roundMinutes = (date: Date) => {
  date.setHours(date.getHours() + Math.round(date.getMinutes()/60));
  date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

  return date.toLocaleString();
}




const Reporting = () => {
  const navigate = useNavigate();
  const gotoPage = (event: any) => {
    event.preventDefault();
    navigate(event.detail.href)
  }
  const [numberOfDocuments, setNumberOfDocuments] = useAtom(numberOfDocumentsAtom)
  const [timePerDocAverage, setTimePerDocAverage] = useAtom(averageTimePerDocumentAtom)
  const [averageConfidence, setAverageConfidence] = useAtom(averageConfidenceAtom)
  const [numberDocsManualRequired, setNumberDocsManualRequired] = useAtom(numberDocsManualRequiredAtom)
  const [averageSummary1Percentage, setAverageSummary1Percentage] = useAtom(averageSummary1PercentageAtom)
  const [averageSummary2Percentage, setAverageSummary2Percentage] = useAtom(averageSummary2PercentageAtom)
  const [pieChartData, setPieChartData] = useAtom(pieChartDataAtom)
  const [batchId] = useAtom(batchIdAtom)
  const [timeSeriesData, setTimeSeriesData] = useAtom(timeSeriesDataAtom)
  const [startDate, setStartDate] = useAtom(startDateAtom)
  const [endDate, setEndDate] = useAtom(endDateAtom)
  // const [networkingData, setNetworkingData] = useAtom(networkingDataAtom)
  const [
    selectedOption,
    setSelectedOption
  ] = useAtom(selectedTimeFrameAtom);
  const options=[
    { label: "All Time", value: "all" },
    { label: "Last 24 hours", value: new Date(new Date().getTime() - (24 * 60 * 60 * 1000)).toLocaleString() },
    { label: "Last 2 days", value: new Date(new Date().getTime() - (2 * 24 * 60 * 60 * 1000)).toLocaleString() },
    { label: "Last 30 days", value: new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000)).toLocaleString() }
  ]
  const timePerDocList: number[] = []
  const confidenceLevels: number[] = []
  const summary1Percentages: number[] = []
  const summary2Percentages: number[] = []
  const typeTotals: {[key: string]: number} = {}
  const dateItems: {[key: string]: string[]} = {}

  const colors = ["green", "pink", "blue", "purple", "orange"]


  // right now we are hard coding which batch to use the data from
  let batchData = (useClaimDocs(batchId).data as ClaimDoc[])
  useEffect(() => {
    if (batchData) {
      console.log("batch data", batchData)
      
      // initialize start and end dates. Currently use the first item in the batch and add 3 days on either side
      setStartDate(new Date(new Date(batchData[0].enrichmentCompletedAt || batchData[0].uploadedAt).getTime() - (3 * 24 * 60 * 60 * 1000)))
      setEndDate(new Date(new Date(batchData[0].enrichmentCompletedAt || batchData[0].uploadedAt).getTime() + (10 * 24 * 60 * 60 * 1000)))

      if (selectedOption.value !== "all") {
        batchData = batchData.filter(item => new Date(item.enrichmentCompletedAt || item.uploadedAt) > new Date(selectedOption.value!))
      }
      batchData.map(item => {
        const dateHourString = roundMinutes(new Date(item.enrichmentCompletedAt || item.uploadedAt))
        Object.keys(dateItems).indexOf(dateHourString) > -1 ? dateItems[dateHourString].push(item.sortKey) : dateItems[dateHourString] = [item.sortKey]
      })
      setTimeSeriesData(dateItems)

      // number of docs
      setNumberOfDocuments(batchData.length)

      // manual confidence
      const onlyLowConfidence = batchData.filter(item => item.documentType.Score < 0.8)
      setNumberDocsManualRequired(onlyLowConfidence.length)

      // iterate through
      for (const item of batchData) {
        confidenceLevels.push(item.documentType.Score*100)
        const itemProcessingTime = item.startTime && item.enrichmentCompletedAt ? calculateProcessingTime(item.startTime, item.enrichmentCompletedAt) : Math.random() * 5
        timePerDocList.push(itemProcessingTime)
        if(item.numberWords) {
          const summary1Words = item.summaries?.summary1.split(" ").length || 0
          const summary2Words = item.summaries?.summary2.split(" ").length || 0
          summary1Percentages.push(100 * summary1Words / item.numberWords)
          summary2Percentages.push(100 * summary2Words / item.numberWords)
          console.log(summary1Percentages)
        } else {
          summary1Percentages.push(Math.random()*10 + 10)
          summary2Percentages.push(Math.random()*8 + 8)
        }
        const typeTotalsKey = item.documentType.Score < 0.8 ? "Manually Classified" : item.documentType.Name
        typeTotals[typeTotalsKey] = typeTotals[typeTotalsKey] ? typeTotals[typeTotalsKey] + 1 : 1
      }
      setTimePerDocAverage(getAverage(timePerDocList))
      setAverageConfidence(getAverage(confidenceLevels))
      setAverageSummary1Percentage(getAverage(summary1Percentages))
      setAverageSummary2Percentage(getAverage(summary2Percentages))
      setPieChartData(Object.keys(typeTotals).map((key, index) => {
        return {"title": key, "value": typeTotals[key], "color": colors[index]}
      }))
      // setNetworkingData(batchData)
    }
  }, [batchData, selectedOption])
  

  const { width } = useWindowDimensions();

  const CounterLink = (props: any) => {
    return (
      <Link variant="awsui-value-large" href="#">
        {props.children}
      </Link>
    );
  };
  const percentageFormatter = (value: number) => `${(value * 100).toFixed(0)}%`;

  useEffect(() => {
    console.log("selectedOption", selectedOption)
  }, [selectedOption])

  return batchData ? (
    <AppLayout
      contentType='wizard'
      minContentWidth={width - 300}
      // disableContentPaddings
      navigationHide={true}
      toolsHide={true}
      breadcrumbs={<BreadcrumbGroup
        items={[
            { text: "Home", href: "/" },
            // { text: "Batches", href: `/${industry.toLowerCase()}` }
        ]}
        ariaLabel="Breadcrumbs"
        expandAriaLabel="Show path"
        onFollow={(event: CustomEvent<BreadcrumbGroupProps.ClickDetail>) => gotoPage(event)}
    />}
      // breadcrumbs={<BCrumb />}
      content={
        <SpaceBetween size="l">
            <Container
              className="custom-dashboard-container"
              header={
                <ColumnLayout columns={3} >
                <Header variant="h2">
                  Document Processing Overview
                </Header>
                </ColumnLayout>

              }

            >
              <ColumnLayout columns={3} variant="text-grid">
              <FormField label="Select Time Frame for All Data">

              <Select 
                  selectedOption={selectedOption} 
                  onChange={({ detail }) => {
                    console.log("detail", detail)
                    setSelectedOption(detail.selectedOption)
                    if(detail.selectedOption.value !== "all") {
                      setStartDate(new Date(new Date(detail.selectedOption.value!).getTime() - (2 * 60 * 60 * 1000)))
                      setEndDate(new Date())
                    } else {
                      console.log("bd 0", batchData[0])
                      // new Date(item.enrichmentCompletedAt || item.uploadedAt)
                      setStartDate(new Date(new Date(batchData[0].enrichmentCompletedAt || batchData[0].uploadedAt).getTime() - (3 * 24 * 60 * 60 * 1000)))
                    }
                  }
                  }
                  options={options}
                />

              </FormField>
              </ColumnLayout>
              <ColumnLayout columns={3} variant="text-grid">
                <div>
                  <Box variant="awsui-key-label">Number of Processed Documents</Box>
                  {/* move select to doc processing overview */}

                  <CounterLink>{numberOfDocuments}</CounterLink>
                </div>
                <div>
                  <Box variant="awsui-key-label">Average Processing Time per Document (Minutes)</Box>
                  <CounterLink>{timePerDocAverage}</CounterLink>
                </div>
                <div>
                  <Box variant="awsui-key-label">Average Classification Confidence Percentage</Box>
                  <CounterLink>{averageConfidence} %</CounterLink>
                </div>
                <div>
                  <Box variant="awsui-key-label">Number of Manually Reviewed Documents</Box>
                  <CounterLink>{numberDocsManualRequired}</CounterLink>
                </div>
                <div>
                  <Box variant="awsui-key-label">Average Summarisation Percentage Model 1</Box>
                  <CounterLink>{averageSummary1Percentage} %</CounterLink>
                </div>
                <div>
                  <Box variant="awsui-key-label">Average Summarisation Percentage Model 2</Box>
                  <CounterLink>{averageSummary2Percentage} %</CounterLink>
                </div>
              </ColumnLayout>
            </Container>
            <Container
              className="custom-dashboard-container"
              header={
                <Header variant="h2">
                  Document Processing By Type
                </Header>
              }
            >
                    <PieChart
        size="medium"
        data={pieChartData}
        ariaLabel="Document Type chart"
        ariaDescription="Pie chart summarizing the classification of documents processed."
        hideFilter={true}
        segmentDescription={(datum, sum) => `${datum.value} document, ${percentageFormatter(datum.value / sum)}`}
        detailPopoverContent={(datum, sum) => [
          {
            key: 'Document count',
            value: datum.value,
          },
          {
            key: 'Percentage',
            value: percentageFormatter(datum.value / sum),
          },
        ]}
        i18nStrings={{
          filterLabel: 'Filter displayed data',
          filterPlaceholder: 'Filter data',
          filterSelectedAriaLabel: 'selected',
          detailPopoverDismissAriaLabel: 'Dismiss',

          legendAriaLabel: 'Legend',
          chartAriaRoleDescription: 'pie chart',
          segmentAriaRoleDescription: 'segment',
        }}
      />
            </Container>
            <TimeSeriesReporting />
            </SpaceBetween>
      }
      contentHeader={<Header variant="h1">Reporting - Case Worker Portal</Header>}
      />
  ) : (
    <Box textAlign="center" variant="p">
      <Spinner size="large" />
    </Box>
  );
}

export default Reporting;