/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect, useState } from "react";
import { AppEnums, AppInterface } from '@aws-prototype/shared';

//polaris
import { Table, TableProps, Header, StatusIndicator } from "@amzn/awsui-components-react/polaris";

// React Query
import { useQueryClient, } from "react-query";

//components
import Info from '../Info';

type docTypes = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.HealthcareDocTypes

interface StatusTableProps {
    document: docTypes;
    inPackage: boolean;
    nextSteps: string;
}

const stepConfiguration = [
    {
        doc: AppEnums.ClaimDocTypes.CMS1500,
        nextStep: 'Send to Claims Database'
    },
    {
        doc: AppEnums.ClaimDocTypes.LICENSE,
        nextStep: 'Send to Customers Database'
    },
    {
        doc: AppEnums.ClaimDocTypes.INSURANCE_ID,
        nextStep: 'Send to Claims Database'
    },
    {
        doc: AppEnums.ClaimDocTypes.INVOICE_RECEIPT,
        nextStep: 'Send to Accounts Payable'
    },
    {
        doc: AppEnums.ClaimDocTypes.DISCHARGE_SUMMARY,
        nextStep: 'Send to File Store'
    },
    {
        doc: AppEnums.ClaimDocTypes.MEDICAL_TRANSCRIPTION,
        nextStep: 'Send to File Store'
    }
]

const stepConfigurationHealthcare = [
    {
        doc: AppEnums.HealthcareDocTypes.CMS1500,
        nextStep: 'Send to Claims Database'
    },
    {
        doc: AppEnums.HealthcareDocTypes.LICENSE,
        nextStep: 'Send to Customers Database'
    },
    {
        doc: AppEnums.HealthcareDocTypes.INSURANCE_ID,
        nextStep: 'Send to Claims Database'
    },
    {
        doc: AppEnums.HealthcareDocTypes.INVOICE_RECEIPT,
        nextStep: 'Send to Accounts Payable'
    },
    {
        doc: AppEnums.HealthcareDocTypes.DISCHARGE_SUMMARY,
        nextStep: 'Send to File Store'
    },
    {
        doc: AppEnums.HealthcareDocTypes.MEDICAL_TRANSCRIPTION,
        nextStep: 'Send to File Store'
    },
    {
        doc: AppEnums.HealthcareDocTypes.PRESCRIPTION,
        nextStep: 'Send to File Store'
    }
]

const stepConfigurationMortg = [
    {
        doc: AppEnums.MortgageDocTypes.URLA1003,
        nextStep: 'Send to Claims Database'
    },
    {
        doc: AppEnums.MortgageDocTypes.BANK_STMT,
        nextStep: 'Send to Customers Database'
    },
    {
        doc: AppEnums.MortgageDocTypes.HOA_STMT,
        nextStep: 'Send to Claims Database'
    },
    {
        doc: AppEnums.MortgageDocTypes.MORTGAGE_NOTE,
        nextStep: 'Send to Accounts Payable'
    },
    {
        doc: AppEnums.MortgageDocTypes.PASSPORT,
        nextStep: 'Send to File Store'
    },
    {
        doc: AppEnums.MortgageDocTypes.PAY_STUB,
        nextStep: 'Send to File Store'
    },
    {
        doc: AppEnums.MortgageDocTypes.W2,
        nextStep: 'Send to File Store'
    }
]

const stepConfigurationPublicSector = [
    {
        doc: AppEnums.ApplicationDocTypes.APPLICATION_FORM,
        nextStep: 'Send to Database'
    },
    {
        doc: AppEnums.ApplicationDocTypes.LICENSE,
        nextStep: 'Send to Customers Database'
    },
    {
        doc: AppEnums.ApplicationDocTypes.BANK_STMT,
        nextStep: 'Send to Accounts Payable'
    },
    {
        doc: AppEnums.ApplicationDocTypes.SSN_CARD,
        nextStep: 'Send to Customers Database'
    },
    {
        doc: AppEnums.ApplicationDocTypes.UTILITY_BILL,
        nextStep: 'Send to File Store'
    }
    // },
    // {
    //     doc: AppEnums.ApplicationDocTypes.UNKNOWN,
    //     nextStep: 'Send to Review'
    // }
]

// table column definitions
const columnDefinitions: TableProps.ColumnDefinition<StatusTableProps>[] = [
    {
        id: 'document',
        header: 'Document',
        cell: (item: StatusTableProps) => item.document
    },
    {
        id: 'inPackage',
        header: 'In Package & Labelled',
        cell: (item: StatusTableProps) => (<StatusIndicator type={item.inPackage ? 'success' : 'error'} />)
    },
    {
        id: 'nextSteps',
        header: 'Next Steps',
        cell: (item: StatusTableProps) => item.nextSteps
    }

];

interface DocumentStatusProps {
    useCase: string
}


const DocumentStatus = (props: DocumentStatusProps) => {
    const queryClient = useQueryClient();
    const [docStatus, setDocStatus] = useState<StatusTableProps[]>([]);
    
    useEffect(() => {
        const docData = queryClient.getQueriesData("idp-insurance-docs")[0][1] as Array<AppInterface.ClaimDoc>;
        
        if(props.useCase === "claim"){
            const tableData = Object.values(AppEnums.ClaimDocTypes).slice(0, 6).map((item: string) => ({
                document: item,
                nextSteps: stepConfiguration.find((step) => step.doc === item)!.nextStep,
                inPackage: docData.findIndex((docDataItem: AppInterface.ClaimDoc) => docDataItem.documentType.Name === item) > -1 ? true : false,
            })) as StatusTableProps[];
    
            if (tableData) { setDocStatus(tableData); }
        }else if (props.useCase === "mortgage"){
            const tableData = Object.values(AppEnums.MortgageDocTypes).slice(0, 6).map((item: string) => ({
                document: item,
                nextSteps: stepConfigurationMortg.find((step) => step.doc === item)!.nextStep,
                inPackage: docData.findIndex((docDataItem: AppInterface.ClaimDoc) => docDataItem.documentType.Name === item) > -1 ? true : false,
            })) as StatusTableProps[];
    
            if (tableData) { setDocStatus(tableData); }
        }else if (props.useCase === "healthcare"){
            const tableData = Object.values(AppEnums.HealthcareDocTypes).slice(0, 7).map((item: string) => ({
                document: item,
                nextSteps: stepConfigurationHealthcare.find((step) => step.doc === item)!.nextStep,
                inPackage: docData.findIndex((docDataItem: AppInterface.ClaimDoc) => docDataItem.documentType.Name === item) > -1 ? true : false,
            })) as StatusTableProps[];
    
            if (tableData) { setDocStatus(tableData); }
        } else if(props.useCase === "publicsector"){
            const tableData = Object.values(AppEnums.ApplicationDocTypes).slice(0, 5).map((item: string) => ({
                document: item,
                nextSteps: stepConfigurationPublicSector.find((step) => step.doc === item)!.nextStep,
                inPackage: docData.findIndex((docDataItem: AppInterface.ClaimDoc) => docDataItem.documentType.Name === item) > -1 ? true : false,
            })) as StatusTableProps[];
    
            if (tableData) { setDocStatus(tableData); }
        }

    }, [queryClient])

    return (
        <Table
            header={<Header variant="awsui-h1-sticky"
                info={<Info />}
            >
                Document Status
            </Header>}
            columnDefinitions={columnDefinitions}
            items={docStatus}
        />
    )
}

export default DocumentStatus;