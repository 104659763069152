/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { HelpPanel, Icon } from "@amzn/awsui-components-react";

const DocumentClassificationHelp = () => {
    return (
        <HelpPanel
            footer={
                <div>
                    <h3>
                        Learn more <Icon name="external" />
                    </h3>
                    <ul>
                        <li>
                            <a target="_blank" rel="noopener noreferrer nofollow" href="https://aws.amazon.com/textract/">Amazon Textract</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noopener noreferrer nofollow" href="https://catalog.us-east-1.prod.workshops.aws/workshops/c2af04b2-54ab-4b3d-be73-c7dd39074b20/en-US/02-getting-started/module-2-doc-extraction">Document Extraction</a>
                        </li>

                    </ul>
                </div>
            }
            header={<h2>Document Extraction</h2>}
        >
            <div>
                Extracting data from documents
                using Amazon TextractAmazon
                Textract is a machine learning
                (ML) service that automatically
                extracts text, handwriting, and
                data from scanned documents. It
                goes beyond simple optical
                character recognition (OCR) to
                identify, understand, and extract
                data from forms and tables.
                Expand the following sections to
                take a brief look at the document
                extraction capabilities of Amazon
                Textract.
            </div>
        </HelpPanel>)
}
export default DocumentClassificationHelp;