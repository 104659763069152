/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

// Polaris Components
import {
  Header, Container, SpaceBetween, Toggle,
} from '@amzn/awsui-components-react';
import { AppEnums, AppInterface, envelopeHighPriorityAtom } from '@aws-prototype/shared';
import { useAtom, atom } from 'jotai';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

type docTypesEnumType = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.ApplicationDocTypes

interface SummaryProps {
  model1Summary: string | undefined,
  model2Summary: string | undefined,
  docType?: docTypesEnumType;
}

export const unableToLoadSummaryText = "Unable to load summary, close and use the information on the next page"

export const Summary = (props: SummaryProps) => {
  const [checked, setChecked] = useAtom(envelopeHighPriorityAtom);
  const queryClient = useQueryClient();
  let { model1Summary, model2Summary } = props;
  if (!model1Summary || !model2Summary) {
    //props
    const { docType } = props;

    const purple = queryClient.getQueriesData("idp-insurance-docs")[0][1]
    console.log("pruple", purple)
    const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as Array<AppInterface.ClaimDoc>)
      .find(item => item.documentType.Name === docType);
    console.log("docData in summaries", docData)
    model1Summary = (docData && docData.summaries) ? docData.summaries.summary1 : unableToLoadSummaryText
    model2Summary = (docData && docData.summaries) ? docData.summaries.summary2 : unableToLoadSummaryText
  }
  return (
    <>
      <SpaceBetween size="s">
        <Toggle
          onChange={({ detail }) =>
            setChecked(detail.checked)
          }
          checked={checked}
        > Mark Envelope High Priority</Toggle>
        <Container header={<Header variant="h3"> Summary from Model 1 </Header>}>
          {model1Summary}
        </Container>
        <Container header={<Header variant="h3"> Summary from Model 2 </Header>}>
          {model2Summary}
        </Container>
      </SpaceBetween>
    </>
  )
}
