/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import React, { useState, useEffect } from "react";
import { Select, SelectProps } from "@amzn/awsui-components-react/polaris";

// store  
import { useDispatch } from 'react-redux'
import { useAppSelector, RootState } from '../../../store';
import { add, remove, ClaimClassifySliceType } from '../../../store/features/claim-classify-feature';

import { AppEnums } from '@aws-prototype/shared';

const docTypesEnum = {
    ...AppEnums.ClaimDocTypes,
    ...AppEnums.MortgageDocTypes,
    ...AppEnums.HealthcareDocTypes,
    ...AppEnums.ApplicationDocTypes,
};

const docTypesValuesEnum = {
    ...AppEnums.ClaimDocTypesValues,
    ...AppEnums.MortgageDocTypesValues,
    ...AppEnums.HealthcareDocTypesValues,
    ...AppEnums.ApplicationDocTypesValues
};
 
 type docTypesEnumType = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.HealthcareDocTypes | AppEnums.ApplicationDocTypes;

interface ClaimClassificationSelectorProps {
    // docType: AppEnums.ClaimDocTypes,
    docType: docTypesEnumType,
    confidence: string,
    useCase: string    
}



const DocumentClassificationSelector = (props: ClaimClassificationSelectorProps) => {
    const dispatch = useDispatch();

    // props
    const { docType, useCase, confidence } = props
    
    const classificationOverrides: Array<ClaimClassifySliceType> = useAppSelector((state: RootState) => state.claimClassify);

    // states
    const [claimClassificationOptions, setClaimClassificationOptions] = useState<SelectProps.Options>([]);
    const [selectedOption, setSelectedOption] = useState<SelectProps.Option>({ label: "UNKNOWN", value: 'Unknown Type' });
    const [confidenceState] = useState<string>(confidence)

    useEffect(() => {
        let classificationOptions: SelectProps.Options = [{
            label: 'Other Document Types',
            options: (useCase === "claim")? Object.entries(AppEnums.ClaimDocTypes).filter(([key, value]) =>
                value !== AppEnums.ClaimDocTypes.UNKNOWN).map(([key, value]) => ({
                    label: AppEnums.ClaimDocTypesValues[value],
                    value: key,
                    description: (value === docType)?`Confidence: ${confidenceState}`: undefined
                })).filter((option) => option.value !== docType)
                :(useCase === "mortgage")? Object.entries(AppEnums.MortgageDocTypes).filter(([key, value]) =>
                value !== AppEnums.MortgageDocTypes.UNKNOWN).map(([key, value]) => ({
                    label: AppEnums.MortgageDocTypesValues[value],
                    value: key,
                    description: (value === docType)?`Confidence: ${confidenceState}`: undefined
                })).filter((option) => option.value !== docType)
                :Object.entries(AppEnums.HealthcareDocTypes).filter(([key, value]) =>
                value !== AppEnums.HealthcareDocTypes.UNKNOWN).map(([key, value]) => ({
                    label: AppEnums.HealthcareDocTypesValues[value],
                    value: key,
                    description: (value === docType)?`Confidence: ${confidenceState}`: undefined
                })).filter((option) => option.value !== docType)
        }]

        let selectedOptionType: SelectProps.Option = {};
        // const selectedType = Object.entries(AppEnums.ClaimDocTypes).find(([key, value]) => key === docType);        
        const selectedType = Object.entries(docTypesEnum).find(([key, value]) => key === docType);        

        if (selectedType) {
            selectedOptionType = {
                value: selectedType[0],
                label: docTypesValuesEnum[selectedType[1]],  //selectedType[1]
                description: `Confidence: ${confidenceState}`
            }
            const overRideItem = classificationOverrides.find((overrideItem: ClaimClassifySliceType) => overrideItem.type === docType);
            if (overRideItem) {
                setSelectedOption({
                    value: overRideItem.value,
                    label: overRideItem.label,
                    description: `Confidence: ${confidenceState}`
                })
            }
            else { setSelectedOption(selectedOptionType); }
        }

        setClaimClassificationOptions(selectedType ? [{
            label: 'Classifier Recommendation',
            options: [selectedOptionType]
        },
        ...classificationOptions]
            : classificationOptions);
    }, [docType, useCase, classificationOverrides]);


    const handleSelectionChange = (detail: SelectProps.ChangeDetail) => {
        setSelectedOption(detail.selectedOption);
        // if (detail.selectedOption.value !== detail.selectedOption.value) {
        if(detail.selectedOption.value !== docType){
            dispatch(add({
                type: docType,
                label: detail.selectedOption.label!,
                value: detail.selectedOption.value!, 
                confidence: confidenceState               
            }));
        } 
        else {
            dispatch(remove({
                type: docType,
                label: detail.selectedOption.label!,
                value: detail.selectedOption.value!,
                confidence: confidenceState
            }));
        }        
    }

    return (
        <Select
            selectedOption={selectedOption}
            expandToViewport
            options={claimClassificationOptions}
            selectedAriaLabel="Selected"
            onChange={({ detail }) => handleSelectionChange(detail)}
        />
    );
}

export default DocumentClassificationSelector;