/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect } from 'react';

// shared imports
import { AppEnums, AppConfigFeatures } from '@aws-prototype/shared'

// Polaris Components
import { SpaceBetween, ExpandableSection } from '@amzn/awsui-components-react';

// components import
import ExtractFeature from './ExtractFeature';
import AnalyzeID from './tabs/AnalyzeID';
import LineItems from './tabs/LineItems';
import SummaryFields from './tabs/SummaryFields';
import Words from './tabs/Words';
import Forms from './tabs/Forms';
import Tables from './tabs/Tables';
import TablesV2 from './tabs/TablesV2';
import Results from './tabs/Results';
import HumanReview from '../HumanReview';

// redux
import { useDispatch } from 'react-redux'
import Query from './tabs/Query';

const docTypesValuesEnum = {
    ...AppEnums.ClaimDocTypesValues,
    ...AppEnums.MortgageDocTypesValues,
    ...AppEnums.HealthcareDocTypesValues,
    ...AppEnums.ApplicationDocTypesValues
 }


const extractPageConfig = [
    {
        docType: AppEnums.ClaimDocTypes.CMS1500,
        tabs: [
            {
                label: "Forms",
                id: "forms",
                content: <Forms docType={AppEnums.ClaimDocTypes.CMS1500} />,
            },
            {
                label: "Tables",
                id: "tables",
                content: <Tables docType={AppEnums.ClaimDocTypes.CMS1500}/>,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
    {
        docType: AppEnums.ClaimDocTypes.INSURANCE_ID,
        tabs: [
            {
                label: "Results",
                id: "results",
                content: <Results docType={AppEnums.ClaimDocTypes.INSURANCE_ID} />,
            },
            {
                label: "Raw Text",
                id: "raw_text",
                content: <Words docType={AppEnums.ClaimDocTypes.INSURANCE_ID} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
    {
        docType: AppEnums.ClaimDocTypes.LICENSE,
        tabs: [
            {
                label: "Results",
                id: "results",
                content: <AnalyzeID docType={AppEnums.ClaimDocTypes.LICENSE} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
    {
        docType: AppEnums.ClaimDocTypes.INVOICE_RECEIPT,
        tabs: [
            {
                label: "Summary Fields",
                id: "summary",
                content: <SummaryFields docType={AppEnums.ClaimDocTypes.INVOICE_RECEIPT} />,
            },
            {
                label: "Line Item Fields",
                id: "line_item",
                content: <LineItems docType={AppEnums.ClaimDocTypes.INVOICE_RECEIPT} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
];

const extractPageConfigMrtg = [
    {
        docType: AppEnums.MortgageDocTypes.URLA1003,
        tabs: [
            {
                label: "Forms",
                id: "forms",
                content: <Forms docType={AppEnums.MortgageDocTypes.URLA1003} />,
            },
            // {
            //     label: "Tables",
            //     id: "tables",
            //     content: <Tables docType={AppEnums.MortgageDocTypes.URLA1003} />,
            // },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
    {
        docType: AppEnums.MortgageDocTypes.W2,
        tabs: [
            {
                label: "Forms",
                id: "forms",
                content: <Forms docType={AppEnums.MortgageDocTypes.W2} />,
            },
            // {
            //     label: "Tables",
            //     id: "tables",
            //     content: <Tables docType={AppEnums.MortgageDocTypes.W2} />,
            // },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
    {
        docType: AppEnums.MortgageDocTypes.PASSPORT,
        tabs: [
            {
                label: "Results",
                id: "results",
                content: <AnalyzeID docType={AppEnums.MortgageDocTypes.PASSPORT} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
    {
        docType: AppEnums.MortgageDocTypes.BANK_STMT,
        tabs: [            
            {
                label: "Tables",
                id: "tables",
                content: <TablesV2 docType={AppEnums.MortgageDocTypes.BANK_STMT}/>,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
    {
        docType: AppEnums.MortgageDocTypes.PAY_STUB,
        tabs: [            
            {
                label: "Tables",
                id: "tables",
                content: <TablesV2 docType={AppEnums.MortgageDocTypes.PAY_STUB} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
];


const extractPageHealthcareConfig = [
    {
        docType: AppEnums.HealthcareDocTypes.CMS1500,
        tabs: [
            {
                label: "Forms",
                id: "forms",
                content: <Forms docType={AppEnums.HealthcareDocTypes.CMS1500} />,
            },
            {
                label: "Tables",
                id: "tables",
                content: <Tables docType={AppEnums.HealthcareDocTypes.CMS1500}/>,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
    {
        docType: AppEnums.HealthcareDocTypes.INSURANCE_ID,
        tabs: [
            {
                label: "Results",
                id: "results",
                content: <Results docType={AppEnums.HealthcareDocTypes.INSURANCE_ID} />,
            },
            {
                label: "Raw Text",
                id: "raw_text",
                content: <Words docType={AppEnums.HealthcareDocTypes.INSURANCE_ID} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
    {
        docType: AppEnums.HealthcareDocTypes.LICENSE,
        tabs: [
            {
                label: "Results",
                id: "results",
                content: <AnalyzeID docType={AppEnums.HealthcareDocTypes.LICENSE} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
    {
        docType: AppEnums.HealthcareDocTypes.INVOICE_RECEIPT,
        tabs: [
            {
                label: "Summary Fields",
                id: "summary",
                content: <SummaryFields docType={AppEnums.HealthcareDocTypes.INVOICE_RECEIPT} />,
            },
            {
                label: "Line Item Fields",
                id: "line_item",
                content: <LineItems docType={AppEnums.HealthcareDocTypes.INVOICE_RECEIPT} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
];

const extractPagePublicSectorConfig = [
    {
        docType: AppEnums.ApplicationDocTypes.APPLICATION_FORM,
        tabs: [
            {
                label: "Forms",
                id: "forms",
                content: <Forms docType={AppEnums.ApplicationDocTypes.APPLICATION_FORM} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
    {
        docType: AppEnums.ClaimDocTypes.LICENSE,
        tabs: [
            {
                label: "Results",
                id: "results",
                content: <AnalyzeID docType={AppEnums.ClaimDocTypes.LICENSE} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
    // {
    //     docType: AppEnums.ApplicationDocTypes.SSN_CARD,
    //     tabs: [
    //         {
    //             label: "Results",
    //             id: "results",
    //             content: <Results docType={AppEnums.ApplicationDocTypes.SSN_CARD} />,
    //         },
    //         {
    //             label: "Raw Text",
    //             id: "raw_text",
    //             content: <Words docType={AppEnums.ApplicationDocTypes.SSN_CARD} />,
    //         },
    //         {
    //             label: "Human Review",
    //             id: "human_review",
    //             content: <HumanReview />,
    //         }
    //     ],
    // },
    {
        docType: AppEnums.ApplicationDocTypes.UTILITY_BILL,
        tabs: [
            {
                label: "Queries",
                id: "queries",
                content: <Query docType={AppEnums.ApplicationDocTypes.UTILITY_BILL} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
    {
        docType: AppEnums.ApplicationDocTypes.BANK_STMT,
        tabs: [           
            {
                label: "Raw Text",
                id: "raw_text",
                content: <Words docType={AppEnums.ApplicationDocTypes.BANK_STMT} />,
            }, 
            {
                label: "Tables",
                id: "tables",
                content: <TablesV2 docType={AppEnums.ApplicationDocTypes.BANK_STMT}/>,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
];

interface DocumentExtractProps {
    useCase: string
}

const DocumentExtract = (props: DocumentExtractProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AppConfigFeatures.setHelpKey(3));
    }, [dispatch])

    return (
        // <div style={{overflowY: 'scroll', maxHeight: '600px', padding: '4px'}}>
            <SpaceBetween size="l">
                {
                    (props.useCase === 'claim')?
                    extractPageConfig.map((feature,index) =>
                        <>
                            <ExpandableSection                                                                                
                                    header={docTypesValuesEnum[feature.docType]} 
                                    disableContentPaddings 
                                    variant='footer' 
                                    defaultExpanded={(index===0)?true:false}
                                >
                                <ExtractFeature
                                    key={feature.docType}
                                    docType={feature.docType}
                                    tabs={feature.tabs} />
                            </ExpandableSection>
                            <hr className='custom-hr'/>
                        </>
                    )
                    :null
                }
                {
             (props.useCase === 'mortgage')?
             extractPageConfigMrtg.map((feature, index) =>
             <>
                 <ExpandableSection                                                                                
                     header={docTypesValuesEnum[feature.docType]} 
                     disableContentPaddings 
                     variant='footer' 
                     defaultExpanded={(index===0)?true:false}
                 >
                     <ExtractFeature
                         key={feature.docType}
                         docType={feature.docType}
                         tabs={feature.tabs} />
                 </ExpandableSection>
                 <hr className='custom-hr'/>
             </>
             )
             :null
                }
                {
                    (props.useCase === 'healthcare')?
                    extractPageHealthcareConfig.map((feature,index) =>
                        <>
                            <ExpandableSection                                                                                
                                    header={docTypesValuesEnum[feature.docType]} 
                                    disableContentPaddings 
                                    variant='footer' 
                                    defaultExpanded={(index===0)?true:false}
                                >
                                <ExtractFeature
                                    key={feature.docType}
                                    docType={feature.docType}
                                    tabs={feature.tabs} />
                            </ExpandableSection>
                            <hr className='custom-hr'/>
                        </>
                    )
                    :null




                }
                {
                    (props.useCase === 'publicsector')?
                    extractPagePublicSectorConfig.map((feature,index) =>
                        <>
                            <ExpandableSection                                                                                
                                    header={docTypesValuesEnum[feature.docType]} 
                                    disableContentPaddings 
                                    variant='footer' 
                                    defaultExpanded={(index===0)?true:false}
                                >
                                <ExtractFeature
                                    key={feature.docType}
                                    docType={feature.docType}
                                    tabs={feature.tabs} />
                            </ExpandableSection>
                            <hr className='custom-hr'/>
                        </>
                    )
                    :null
                }


            </SpaceBetween>
        // </div>
    )
}

export default DocumentExtract;