/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

 import React from 'react';
 // Polaris Components
 import {
     Wizard,
     WizardProps,
     Alert,
     Spinner,
     Header,
     BreadcrumbGroup,
     Badge,
     BreadcrumbGroupProps,
     AppLayout,
     Box,
 } from '@amzn/awsui-components-react';
// navigation
import { useNavigate, useParams } from "react-router-dom";
import { routeNames, routes } from '../../common/route';

// React query 
import { useClaimDocs } from '../../hooks/useClaim';
import { useWindowDimensions } from '../../hooks/useWindowDiemnsions';

// Components
import Info from '../components/Info';
import ClaimDocuments from '../components/ClaimDocuments';
import DocumentClassify from '../components/classify/DocumentClassify';
import DocumentExtract from '../components/extract/DocumentExtract';
import ExtractEntity from '../components/entity-extract/ExtractEntity';
import Enrich from '../components/enrich/Enrich';
import Review from '../components/review/ClaimReview';

const steps: WizardProps.Step[] = [
    {
        title: 'Healthcare Document Capture',
        info: <Info helpKey={1} />,
        content: <ClaimDocuments useCase='healthcare'/>,
        description: "List of  unlabeled documents uploaded as a package by user for Healthcare claims processing.",
    },
    {
        title: 'Document Classification',
        info: <Info helpKey={2} />,
        content: <DocumentClassify  useCase='healthcare'/>,
        description: "Labeled accurately categorized documents for Healthcare claims processing with options for human override.",
    },
    {
        title: 'Document Extraction',
        info: <Info helpKey={3} />,
        content: <DocumentExtract  useCase='healthcare'/>,
        description: "Accurate extraction of documents for Healthcare claims processing.",
    },
    {
        title: 'Document Extraction Continued',
        info: <Info helpKey={4} />,
        content: <ExtractEntity  useCase='healthcare'/>,
        description: "Accurate elements extraction from documents for Healthcare claims processing.",
    },
    {
        title: 'Document Enrichment',
        info: <Info helpKey={5} />,
        content: <Enrich  useCase='healthcare'/>,
        description: "Enrich documents by extracting key information.",
    },
    {
        title: 'Document Review & Verification',
        info: <Info helpKey={6} />,
        content: <Review  useCase='healthcare'/>,
        description: "Review documents and verify extracted features and their associated values.",
    },
];

const i18nStrings: WizardProps.I18nStrings = {
    stepNumberLabel: stepNumber => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
    cancelButton: 'Cancel',
    previousButton: 'Previous',
    nextButton: 'Next',
    submitButton: 'Send to Claim Decision System',
    optional: 'optional',
    skipToButtonLabel: (step) =>
        `Skip to ${step.title}`,
};


const AnalyzeHealthcareClaim = () => {
    const [
        activeStepIndex,
        setActiveStepIndex
    ] = React.useState(0);

    const navigate = useNavigate();
    // params
    const { claimid } = useParams();
    const { width } = useWindowDimensions();

    // RQ Fetch
    const { isError, isFetched, isFetching, } = useClaimDocs(claimid!);

    const gotoPage = (event: any) => {
        event.preventDefault();
        navigate(event.detail.href)
    }

    const WizHeader = () => {
        return <>
            <Header variant="h1">Healthcare - portal</Header>
            <Badge color="blue">Healthcare Claim Packet- {claimid}</Badge>
        </>
    }    

    const BCrumb = () => {
        return (<BreadcrumbGroup
            items={[
                { text: "Home", href: "/" },
                { text: "HealthCare", href: "/healthcare" },
                { text: "Claim packet", href: "#components" }
            ]}
            ariaLabel="Breadcrumbs"
            expandAriaLabel="Show path"
            onFollow={(event: CustomEvent<BreadcrumbGroupProps.ClickDetail>) => gotoPage(event)}
        />)
    }

    if (isFetching) {
        return <AppLayout
            contentType='wizard'
            minContentWidth={width - 300}
            //disableContentPaddings
            navigationHide={true}
            toolsHide={true}
            breadcrumbs={<BCrumb />}
            disableContentHeaderOverlap
            content={
                <Box textAlign="center" variant="p">
                    <Spinner size="large" />
                </Box>
            }
            contentHeader={<WizHeader />}
        />
    }


    return (
        <AppLayout
            contentType='wizard'
            minContentWidth={width - 300}
            // disableContentPaddings
            navigationHide={true}
            toolsHide={true}
            breadcrumbs={<BCrumb />}
            content={
                <>
                    {
                        (isError) &&
                        <Alert type='error' header="Unable to fetch Healthcare Documents">
                            We are unable to fetch the list of Healthcare documents at this time. Please try again later.
                        </Alert>
                    }
                    {
                        (isFetched) &&
                        <Wizard steps={steps}
                            activeStepIndex={activeStepIndex}
                            onNavigate={({ detail }) =>
                                setActiveStepIndex(detail.requestedStepIndex)
                            }
                            i18nStrings={i18nStrings}
                            allowSkipTo
                            onCancel={() => navigate(routes[routeNames.Healthcare].href)}
                            onSubmit={() => navigate(routes[routeNames.Home].href)}
                        />
                    }
                </>
            }
            contentHeader={<WizHeader />}
        />
    )
}



export default AnalyzeHealthcareClaim;