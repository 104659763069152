/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect, useState } from "react";
import { AppEnums, AppInterface } from '@aws-prototype/shared';

import { useLocation } from "react-router-dom";

//polaris
import { Spinner, TextContent, SpaceBetween } from "@amzn/awsui-components-react/polaris";

// React Query
import { useQueryClient, } from "react-query";
import { useClaimAnalyzeDoc } from '../../../../hooks/useClaim';

// components
import KeyPair from '../../KeyPair';
 
 type docTypesEnumType = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.HealthcareDocTypes | AppEnums.ApplicationDocTypes

interface FormsProps {
    // docType: AppEnums.ClaimDocTypes;
    docType: docTypesEnumType;
}

const Forms = (props: FormsProps) => {
    const location = useLocation()    
    // states
    const [formKeyPair, setFormKeyPair] = useState<AppInterface.KeyValue[]>();

    //props
    const { docType } = props;
    const queryClient = useQueryClient();

    const purple = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as Array<AppInterface.ClaimDoc>)
    const docData = purple
        .find(item => item.documentType.Name === docType);    
    // RQ Fetch
    const { data } = useClaimAnalyzeDoc(docData!.hashKey, docData!.sortKey, AppEnums.DocumentOutput.FORMS);
    useEffect(() => {
        if (data && data.length > 0) {
            const customKeys = [
                "(Medicare#)",
                "(Medicaid#)",
                "1a. INSURED'S I.D. NUMBER",
                "4. INSURED'S NAME (Last Name, First Name, Middle Initial)",
                "7. INSURED'S ADDRESS (No., Street)",
                "CITY",
                "STATE",
                "ZIP CODE",
                "TELEPHONE (Include Area Code)",
                "11. INSURED'S POLICY GROUP OR FECA NUMBER",
                "DATE",
                "MM",
                "DD",
                "YY",
                "M",
                "F",
                "2. PATIENT'S NAME (Last Name, First Name, Midde Initial)",
                "5. PATIENT'S ADDRESS (No., Street)",
                "CITY",
                "STATE",
                "ZIP CODE",
                "MARRIED",
                "C. INSURANCE PLAN NAME OR PROGR/ AM NAME",
                "B.",
                "C.",
                "D.",
                "E.",
                "25. FEDERAL TAX I.D. NUMBER",
                "SSN EIN",
                "32. SERVICE FACILITY LOCATION INFORMATION",
                "33. BILLING PROVIDER INFO & PH #",
                "28. TOTAL CHARGE",
            ];
            let formKPintermediate = data.filter((dataItem: AppInterface.TextractFormKeyValue) =>dataItem.value!.text.length > 1)
                .filter((dItem: AppInterface.TextractFormKeyValue) => dItem.key)
            if (!(location.pathname.includes("/publicsector"))) {
                formKPintermediate = formKPintermediate.filter((dItem: AppInterface.TextractFormKeyValue) => dItem.key && customKeys.includes(dItem.key.text))
            }
            let formKP = formKPintermediate
                                    .map((item: AppInterface.TextractFormKeyValue) => ({
                                                    key: item.key?.text,
                                                    value: item.value?.text
                                                } as AppInterface.KeyValue
                                            )
                                        );            
            if(location.pathname.includes("/mortgage") && formKP.length === 0){
                const customMrtgKeys = [
                    "made",
                    "Own",
                    "to be made",
                    "Rent",
                    "Ages"
                ];
                formKP = data.filter((dataItem: AppInterface.TextractFormKeyValue) =>dataItem.value!.text.length > 1)
                .filter((dItem: AppInterface.TextractFormKeyValue) => dItem.key && !customMrtgKeys.includes(dItem.key.text))
                .map((item: AppInterface.TextractFormKeyValue) => ({
                                key: item.key?.text,
                                value: item.value?.text
                            } as AppInterface.KeyValue
                        )
                    );
            }
            setFormKeyPair(formKP);
        }
    }, [data, location.pathname])


    return (
        <div style={{ display: 'flex', padding: 10, height: window.innerHeight * 0.8, overflow: 'auto', }}>
            <SpaceBetween size="l">
                {formKeyPair ? <KeyPair data={formKeyPair} form /> : <Spinner />}
                <TextContent>
                    <p><b>Note:</b> some of the items have been filtered for the demo</p>
                </TextContent>
            </SpaceBetween>
        </div>
    )
}

export default Forms;