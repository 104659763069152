/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
 import React, {useState} from "react";
 import { AppEnums, AppInterface } from '@aws-prototype/shared';
 
 // React Query
 import { useQueryClient, } from "react-query";
 import { useTextractQuery } from "src/hooks/useTextractQuery";
 
 //polaris
 import { Table, Select, SpaceBetween, Spinner, TextContent} from "@amzn/awsui-components-react/polaris";
  
type docTypesEnumType = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.ApplicationDocTypes;
 
 interface TableProps {
     docType: docTypesEnumType;
 }
 
 //This V2 Component supports multiple document for a single page 
 //it consumes the direct AnalyzeDocument Output of textract and renders the
 //tables using the data returned by useTextractTable Hook
 
 const Query = (props: TableProps) => {
     //props
     const { docType } = props;
     const queryClient = useQueryClient();   
     
     const [selectedOption, setSelectedOption] = useState({});
     const [answerList, setanswerList] = useState([])
 
     const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as Array<AppInterface.ClaimDoc>)
         .find(item => item.documentType.Name === docType);
     
     const {loading, textractQueries} = useTextractQuery(docData?.textractDocumentAnalysisOutput);

     const onChange = (option: any) => {
        setSelectedOption(option);
        const {answers} = option.data;
        const allAnswers = answers.map((answer: any)=> {
            return {
                answer: answer.Text,
                confidence: `${answer.Confidence}%`
            }
        });
        setanswerList(allAnswers);
     }
 
     return (
         <div style={{ height: window.innerHeight * 0.8, overflow: 'auto', }}>
             <SpaceBetween size="l" direction="vertical">
             {(!loading) ?
                <>
                    <SpaceBetween size="xs">
                        <TextContent>
                            <h4>Queries</h4>
                        </TextContent>
                        <Select
                            selectedOption={selectedOption}
                            placeholder="Select a query"
                            onChange={({ detail }) =>
                                onChange(detail.selectedOption)
                            }
                            options={
                                textractQueries.map((query:any)=>{
                                    return {
                                        label: query.query,
                                        value: query.query,
                                        data: query
                                    }
                                })
                            }
                            selectedAriaLabel="Selected"
                        />
                    </SpaceBetween>
                    <Table
                        variant="embedded"
                        columnDefinitions={[
                            {
                                id: "answer",
                                header: "Answer",
                                cell: (e:any) => e.answer
                              },
                              {
                                id: "confidence",
                                header: "Confidence",
                                cell: e => e.confidence
                              }
                        ]}
                        items={answerList}
                        empty={<TextContent><p style={{textAlign: 'center'}}><i>Select a question to see answer</i></p></TextContent>}
                    />
                </>
                :<Spinner/>
             }
            </SpaceBetween>
         </div>
 
     )
 }
 
 export default Query; 