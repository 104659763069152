/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect, useState } from "react";
import { AppEnums, AppInterface } from '@aws-prototype/shared';

//polaris
import { Spinner, ColumnLayout, Textarea } from "@amzn/awsui-components-react/polaris";

// React Query
import { useQueryClient, } from "react-query";
import { useClaimAnalyzeDoc } from '../../../../hooks/useClaim';

type docTypesEnumType = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes
interface LineProps {
    docType: docTypesEnumType;
}

const Lines = (props: LineProps) => {
    //props
    const { docType } = props;
    const queryClient = useQueryClient();

    // states
    const [lineTexts, setLineTexts] = useState<string[]>();

    const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as Array<AppInterface.ClaimDoc>)
        .find(item => item.documentType.Name === docType);

    // RQ Fetch
    const { data } = useClaimAnalyzeDoc(docData!.hashKey, docData!.sortKey, AppEnums.DocumentOutput.LINES)

    useEffect(() => {
        if (data) {
            setLineTexts(data.map((item: any) => item.text));
        }
    }, [data])

    return (
        <div style={{ padding: 5, height: window.innerHeight * 0.8, overflow: 'auto', }}>
            {lineTexts && <ColumnLayout columns={2}>
                {lineTexts.map((item: string, index: number) => <Textarea
                        key={item + index}
                        value={item}
                        disabled
                    />)}
            </ColumnLayout>}

            {!lineTexts && <Spinner />}
        </div>
    )
}

export default Lines;