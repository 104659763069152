/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { HelpPanel, Icon } from "@amzn/awsui-components-react";

const ReviewHelp = () => {
    return (
        <HelpPanel
            footer={
                <div>
                    <h3>
                        Learn more <Icon name="external" />
                    </h3>
                    <ul>
                        <li>
                            <a target="_blank" rel="noopener noreferrer nofollow" href="https://aws.amazon.com/augmented-ai/">Amazon Augmented AI (Amazon A2I)</a>
                        </li>

                    </ul>
                </div>
            }
            header={<h2>Amazon Augmented AI (Amazon A2I)</h2>}
        >
            <div>
                Amazon Augmented AI
                (Amazon A2I) makes it easy
                to build the workflows required
                for human review of ML
                predictions. Amazon A2I brings
                human review to all
                developers, removing the
                undifferentiated heavy lifting
                associated with building human
                review systems or managing
                large numbers of human
                reviewers.
            </div>
        </HelpPanel>)
}
export default ReviewHelp;