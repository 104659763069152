import 'regenerator-runtime/runtime'
import { useState, useEffect } from "react";
import { getDocumentAsJSON } from "src/utils"


export const useTextractQuery = (key) => {
    const [textractQueries, settextractQueries] = useState([]);
    const [loading, setloading] = useState(true);

    const getQueriesContent = async (key) => {
        setloading(true);
        const jsonData = await getDocumentAsJSON(key);
        let Blocks;
        if (Array.isArray(jsonData)){
            Blocks = jsonData[0].Blocks;
        } else {
            Blocks = jsonData.Blocks;
        }

        const queries = Blocks.filter((block) => block.BlockType === 'QUERY' || block.BlockType === 'QUERY_RESULT');

        const qna = []
        for (const query of queries) {
            const querynanswer = {};
            let answersObjs = [];
            if (query.BlockType === "QUERY") {
                querynanswer['query'] = query.Query.Text;
                const { Relationships } = query;
                for (const relation of Relationships) {
                    if (relation.Type === 'ANSWER') {
                        for (const answerId of relation.Ids) {
                            answersObjs = Blocks.filter((block) => block.BlockType === 'QUERY_RESULT' && block.Id === answerId);
                        }
                    }
                }
                querynanswer['answers'] = answersObjs;
            }
            (Object.keys(querynanswer).length !== 0) && qna.push(querynanswer)
        }

        settextractQueries(qna);
        setloading(false);
    }

    useEffect(() => {
        getQueriesContent(key)
    }, [key])

    return { loading, textractQueries }
}