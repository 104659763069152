import 'regenerator-runtime/runtime'
import { useState, useEffect } from "react";
import { getDocumentAsJSON } from "src/utils"
import { TextractDocument } from "amazon-textract-response-parser/dist/es";

export const useTextractTable = (key) => {
    const [textractTables, settableData] = useState([]);
    const [loading, setloading] = useState(true);

    const getTablesContent = async (key) => {
        setloading(true);
        const jsonData = await getDocumentAsJSON(key);
        // console.log(jsonData)
        const doc = new TextractDocument(jsonData);
        let tables = [];

        for (const page of doc.iterPages()) {
            for (const table of page.iterTables()) {
                let i = 1, rows = [], header = [];
                header = table.cellsAt(i, null)?.map((cell) => cell.text);
                const currentTable = {
                    header: header
                }
                i++;
                for (const rowobj of table.listRows()) {
                    let row = {}, j = 0;
                    for (const cell of table.cellsAt(i, null, true)?.map((cell) => cell.text)) {
                        row[header[j]] = cell;
                        j++;
                    }
                    (Object.keys(row).length !== 0) && rows.push(row);
                    i++;
                }
                currentTable['rows'] = rows
                tables.push(currentTable);
            }
        }
        settableData(tables);
        setloading(false);
    }

    useEffect(() => {
        getTablesContent(key)
    }, [key])

    return { loading, textractTables }
}