/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect, useState } from "react";
import { AppEnums, AppInterface } from '@aws-prototype/shared';

// Polaris Components
import { Table, TableProps, Spinner, SpaceBetween, TextContent } from '@amzn/awsui-components-react';

// React Query
import { useQueryClient, } from "react-query";

// utils    
import { getDocumentAsJSON } from '../../../utils';

type docTypesEnumType = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.ApplicationDocTypes;
interface CustomEntitiesProps {
    docType: docTypesEnumType;
}

interface EntityTableProps {
    Text: string;
    Type: string;
    Score: number;
}

const CUSTOM_ENTITIES_KEY = 'comprehendCustomEntitiesOutput';

const CustomEntities = (props: CustomEntitiesProps) => {

    //props
    const { docType } = props;
    const queryClient = useQueryClient();

    const [customEntities, setCustomEntities] = useState<EntityTableProps[]>();

    // retrieve data from RQ cache
    const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as
        Array<AppInterface.ClaimDoc>).find(item => item.documentType.Name === docType);

    useEffect(() => {
        const getCustomEntities = async () => {
            const { Entities }: any = await getDocumentAsJSON(docData![CUSTOM_ENTITIES_KEY]!);
            setCustomEntities(Entities.filter((entity: EntityTableProps) => !entity.Type.includes('HOSPITAL')));
        }

        if (docType && docData && docData.documentType.Name === docType) {
            getCustomEntities();
        }

    }, [docData, docType]);

    // table column definitions
    const columnDefinitions: TableProps.ColumnDefinition<EntityTableProps>[] = [
        {
            id: 'Text',
            header: 'Entity',                        
            cell: (item: EntityTableProps) => (item.Text.split(':')[1] || item.Text)
        },
        {
            id: 'Type',
            header: 'Type',            
            cell: (item: EntityTableProps) => item.Type
        },
        {
            id: 'Score',
            header: 'Confidence',
            cell: (item: EntityTableProps) => (`${(item.Score * 100).toFixed(2)}%`)
        }
    ];

    return (
        <div style={{ padding: 5, height: window.innerHeight * 0.8, overflow: 'auto', }}>
            <SpaceBetween size="l">
                {customEntities ? <Table
                    columnDefinitions={columnDefinitions}
                    items={customEntities}                    
                    resizableColumns
                    wrapLines
                    variant="embedded"
                /> : <Spinner />}
                <TextContent>
                    <p><b>Note:</b> some of the items have been filtered for the demo</p>
                </TextContent>
            </SpaceBetween>
        </div>
    )

}

export default CustomEntities;