import { API } from 'aws-amplify';
import { useQuery } from "react-query";
import { AppInterface, AppEnums } from '@aws-prototype/shared';

// date time
import { DateTime } from "luxon";

const getClaimsByHashkey = async (hashKey: string) => {
    try {
        const response = await API.get('IdpApi', 'claims', { queryStringParameters: { hashKey } });
        return response.filter((resp: any) => !resp.sortKey.includes('BR')).sort((a: AppInterface.Claim, b: AppInterface.Claim) => a.status === AppEnums.ClaimStates.PENDING_REVIEW ? -1 : 1)
    } catch (err: any) {
        throw new Error(err)
    }
}

export const getClaimDocs = async (hashKey: string) => {
    try {
        const response = await API.get('IdpApi', 'claims', { queryStringParameters: { hashKey } });
        const arr_resp = response.filter((resp: any) => !resp.sortKey.includes('BR')).map((item: AppInterface.ClaimDoc) => ({
            hashKey: item.hashKey,
            sortKey: item.sortKey,
            documentType: {
                Score: item.documentType.Score,
                Name: item.documentType.Name,
            },
            uploadedAt: DateTime.fromISO(item.uploadedAt).toLocaleString(DateTime.DATE_MED),
            // fileType: item.sortKey.split('.').pop(),
            fileSize: `${Math.ceil(item.fileSize / (1000))} KB`,
            ...(item.confidence && { confidence: item.confidence }),
            uploadLocation: item.uploadLocation,
            ...(item.textractAnalyzeIDOutput && { textractAnalyzeIDOutput: item.textractAnalyzeIDOutput }),
            ...(item.textractDetectTextOutput && { textractDetectTextOutput: item.textractDetectTextOutput }),
            ...(item.textractDocumentAnalysisOutput && { textractDocumentAnalysisOutput: item.textractDocumentAnalysisOutput }),
            ...(item.textractExpenseAnalysisOutput && { textractExpenseAnalysisOutput: item.textractExpenseAnalysisOutput }),
            ...(item.textractPlainTextOutput && { textractPlainTextOutput: item.textractPlainTextOutput }),
            ...(item.comprehendClassificationOutput && { comprehendClassificationOutput: item.comprehendClassificationOutput }),
            ...(item.comprehendCustomEntitiesOutput && { comprehendCustomEntitiesOutput: item.comprehendCustomEntitiesOutput }),
            ...(item.comprehendEntitiesOutput && { comprehendEntitiesOutput: item.comprehendEntitiesOutput }),
            ...(item.comprehendMedicalICD10CMOutput && { comprehendMedicalICD10CMOutput: item.comprehendMedicalICD10CMOutput }),
            ...(item.comprehendPiiEntitiesOutput && { comprehendPiiEntitiesOutput: item.comprehendPiiEntitiesOutput }),
            ...(item.summaries && {summaries: item.summaries}),
            ...(item.enrichmentCompletedAt && {enrichmentCompletedAt: item.enrichmentCompletedAt}),
            startTime: item.uploadedAt,
            numberWords: item.numberWords,
        }));
        // console.log(arr_resp)
        return arr_resp;
    } catch (err: any) {
        throw new Error(err)
    }
}

export const getBusinessRules = async (hashKey: string) => {
    console.log(hashKey)
    try {
        const response = await API.get('IdpApi', 'claims', { queryStringParameters: { hashKey } });
        return response.filter((resp: any) => resp.sortKey.includes('BR'))
    } catch (err: any) {
        throw new Error(err)
    }
}

const getAnalyzeDoc = async (hashKey: string, sortKey: string, output: AppEnums.DocumentOutput) => {
    try {
        return await API.get('IdpApi', `document?hashKey=${hashKey}&sortKey=${sortKey}&output=${output}`, {});
    } catch (err: any) {
        throw new Error(err)
    }
}

export const useClaim = (hashKey: string) => {
    return useQuery(["idp-insurance-claims"], () => getClaimsByHashkey(hashKey)
    );
}

export const useClaimDocs = (hashKey: string) => {
    return useQuery(["idp-insurance-docs"], () => getClaimDocs(hashKey), {
        refetchOnWindowFocus: false, // avoid unnecessary re-fetches         
    });
}

export const useClaimBR = (hashKey: string) => {
    return useQuery(["idp-insurance-BRS"], () => getBusinessRules(hashKey), {
        refetchOnWindowFocus: false, // avoid unnecessary re-fetches           
    })
}

export const useClaimAnalyzeDoc = (hashKey: string, sortKey: string, output: AppEnums.DocumentOutput) => {
    return useQuery([`idp-insurance-Analyze-${sortKey}-${output}`], () => getAnalyzeDoc(hashKey, sortKey, output)
    )
}