/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
 import { useEffect, useState } from 'react';

 // Polaris UI
 import { StatusIndicator, TextContent } from '@amzn/awsui-components-react';
 
 // enums
 import { AppEnums, AppInterface } from '@aws-prototype/shared';
 
 // data import
 import { MRTG_STATUS_MESSAGE } from '../../../data/Mortgage';
 
 
 interface ClaimStatusIndicatorStatus {
     status: string
     description?: boolean;
 }
 
 const MrtgStatusIndicator = ({ status, description }: ClaimStatusIndicatorStatus) => {
     const [claimStatusItem, setClaimStatusItem] = useState<AppInterface.ClaimStatus | undefined>();
 
     useEffect(() => {
         setClaimStatusItem(MRTG_STATUS_MESSAGE.find(item => item.type === status));
     }, [status])
 
 
     return (
         <>
             {description && <TextContent>{claimStatusItem?.content}</TextContent>}
             {!description &&
                 <StatusIndicator type={claimStatusItem ? claimStatusItem.status : AppEnums.Status.LOADING}>
                     {claimStatusItem ? claimStatusItem.header : 'Loading'}
                 </StatusIndicator>}
 
         </>
     )
 }
 
 export default MrtgStatusIndicator;