/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect } from 'react';
// Polaris Components
import {
    Container, Header, Button, SpaceBetween, Tabs, Grid,
} from '@amzn/awsui-components-react';

// shared
import { AppInterface, AppEnums, ExtractFeatures } from '@aws-prototype/shared';
import { useDispatch } from 'react-redux'

// React Query
import { useQueryClient, } from "react-query";

// Component
import DocumentCanvas from '../DocumentCanvas';

const docTypesValuesEnum = {
    ...AppEnums.ClaimDocTypesValues,
    ...AppEnums.MortgageDocTypesValues,
    ...AppEnums.HealthcareDocTypesValues,
    ...AppEnums.ApplicationDocTypesValues
 }
 
type docTypesEnumType = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.HealthcareDocTypes | AppEnums.ApplicationDocTypes

interface ExtractFeatureProps {
    // docType: AppEnums.ClaimDocTypes,    
    docType: docTypesEnumType,
    tabs: {
        label: string;
        id: string;
        content: JSX.Element;
    }[]
}

const ExtractFeature = (props: ExtractFeatureProps) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    // props
    const { tabs, docType } = props;

    const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as Array<AppInterface.ClaimDoc>)
        .find(item => item.documentType.Name === docType);

    const handleZoomIn = () => {
        dispatch(ExtractFeatures.zoomIn(docType));
    }

    const handleZoomOut = () => {
        dispatch(ExtractFeatures.zoomOut(docType));
    }

    const handleZoomReset = () => {
        dispatch(ExtractFeatures.zoomReset(docType));

    }

    useEffect(() => {
      if(docType=== AppEnums.MortgageDocTypes.PAY_STUB){
        dispatch(ExtractFeatures.setSelectedTab({ doctype: docType, activeTabId: 'pii' }))
      }
    }, [])
    

    return (
        <>
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                <Container disableContentPaddings header={<Header variant="h3"
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button iconName="view-full" variant="icon" onClick={handleZoomReset} />
                                    <Button iconName="zoom-out" variant="icon"
                                        onClick={handleZoomOut} />
                                    <Button iconName="zoom-in" variant="icon"
                                        onClick={handleZoomIn} />
                                </SpaceBetween>
                            }
                        >
                            {/* {AppEnums.ClaimDocTypesValues[docType]} */}
                            {docTypesValuesEnum[docType]}
                        </Header>}>
                    <div style={{
                        overflow: 'auto',                        
                    }}>
                        {docData &&
                            <DocumentCanvas
                                docData={docData}
                            />}
                    </div>
                </Container>

                <Tabs
                    variant="container"
                    tabs={tabs}
                    onChange={({ detail }) =>
                        dispatch(ExtractFeatures.setSelectedTab({ doctype: docType, activeTabId: detail.activeTabId }))}
                />
            </Grid>

        </>

    )
}

export default ExtractFeature;