/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect, useState } from "react";
import { AppEnums, AppInterface } from '@aws-prototype/shared';

//polaris
import { Spinner } from "@amzn/awsui-components-react/polaris";

// React Query
import { useQueryClient, } from "react-query";
import { useClaimAnalyzeDoc } from '../../../../hooks/useClaim';

// components
import KeyPair from '../../KeyPair';
 
 type docTypesEnumType = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.HealthcareDocTypes | AppEnums.ApplicationDocTypes

interface FormsProps {
    // docType: AppEnums.ClaimDocTypes;
    docType: docTypesEnumType;
}

const Results = (props: FormsProps) => {
    // states
    const [formKeyPair, setFormKeyPair] = useState<AppInterface.KeyValue[]>();

    //props
    const { docType } = props;
    const queryClient = useQueryClient();

    const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as Array<AppInterface.ClaimDoc>)
        .find(item => item.documentType.Name === docType);

    // RQ Fetch
    const { data } = useClaimAnalyzeDoc(docData!.hashKey, docData!.sortKey, AppEnums.DocumentOutput.FORMS);

    useEffect(() => {
        if (data && data.length > 0) {
            setFormKeyPair(data.filter((dataItem: AppInterface.TextractFormKeyValue) =>
                dataItem.value!.text.length > 1).map((item: AppInterface.TextractFormKeyValue) => ({
                    key: item.key?.text,
                    value: item.value?.text
                } as AppInterface.KeyValue)));
        }
    }, [data])

    return (
        <div style={{ display: 'flex', padding: 10, height: window.innerHeight * 0.8, overflow: 'auto', }}>
            {formKeyPair ? <KeyPair data={formKeyPair} form /> : <Spinner />}
        </div>
    )

}

export default Results;

