/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
 import React from "react";
 import { AppEnums, AppInterface } from '@aws-prototype/shared';
 
 // React Query
 import { useQueryClient, } from "react-query";
 import { useTextractTable } from "src/hooks/useTextractTable";
 
 //polaris
 import { Table, SpaceBetween, Spinner} from "@amzn/awsui-components-react/polaris";
  
  type docTypesEnumType = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.ApplicationDocTypes
 
 interface TableProps {
     docType: docTypesEnumType;
 }
 
 //This V2 Component supports multiple document for a single page 
 //it consumes the direct AnalyzeDocument Output of textract and renders the
 //tables using the data returned by useTextractTable Hook
 
 const TablesV2 = (props: TableProps) => {
     //props
     const { docType } = props;
     const queryClient = useQueryClient();    
 
     const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as Array<AppInterface.ClaimDoc>)
         .find(item => item.documentType.Name === docType);
     
    //  console.log(docData?.textractDocumentAnalysisOutput);
 
     const {loading, textractTables} = useTextractTable(docData?.textractDocumentAnalysisOutput);
 
     return (
         <div style={{ height: window.innerHeight * 0.8, overflow: 'auto', }}>
             <SpaceBetween size="m" direction="vertical">
             {(!loading) ?
                textractTables.map((tab:any, index:number) => {
                    return <>                                        
                        <Table
                            key={index}
                            variant="embedded"
                            columnDefinitions={
                                tab['header'].map((headerItem: string) => ({
                                    id: headerItem,
                                    header: headerItem,
                                    cell: (item:any) => item[headerItem],
                                }))
                            }
                            items={tab.rows}                    
                        />
                        <hr/>
                    </>
                    
                })
                :<Spinner/>
             }
            </SpaceBetween>
         </div>
 
     )
 }
 
 export default TablesV2; 