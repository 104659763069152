/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
 import { useEffect, useState } from "react";
 import { AppEnums, AppInterface } from '@aws-prototype/shared';
 
 // Polaris Components
 import { Table, TableProps, Spinner } from '@amzn/awsui-components-react';
 
 // React Query
 import { useQueryClient, } from "react-query";
 
 // utils
 import { getDocumentAsJSON } from '../../../utils';

 
 interface RxNormProps {
    docType: AppEnums.HealthcareDocTypes;
}

interface RxNormTableProps {
    Text: string;
    Code: Text;
    Description: Text;
    Score: number;
}

const COMP_CLASSIFICATION_KEY = 'comprehendMedicalICD10CMOutput'; // overloading this attrib to store rxnormcodes, avoiding breaking lambda server code 

const RxNorm = (props: RxNormProps) => {

    //props
    const { docType } = props;
    const queryClient = useQueryClient();

    // state
    const [RxNormData, setRxNormData] = useState<RxNormTableProps[]>();


    // retrieve data from RQ cache
    const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as
        Array<AppInterface.HealthcareDoc>).find(item => item.documentType.Name === docType);


    useEffect(() => {
        const getComprehendClassification = async () => {
            const { Entities }: any = await getDocumentAsJSON(docData![COMP_CLASSIFICATION_KEY]!);
            Entities?.sort((a: { Score: number; },b: { Score: number; }) => b.Score - a.Score);
            setRxNormData(Entities.map((entity: any) =>
                entity.RxNormConcepts.flatMap((concept: any) => [{ ...concept, Text: entity.Text }])).flat())
        }
        if (docType && docData && docData.documentType.Name === docType) {
            getComprehendClassification();
        }
    }, [docData, docType]);

    const toTitleCase = (text: string) => {
        return text
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    // table column definitions
    const columnDefinitions: TableProps.ColumnDefinition<RxNormTableProps>[] = [
        {
            id: 'Text',
            header: 'Entity',
            cell: (item: RxNormTableProps) => (toTitleCase(item.Text)),
            minWidth: 120,
        },
        {
            id: 'Code',
            header: 'RxNorm Code',
            cell: (item: RxNormTableProps) => item.Code,
            minWidth: 80,
        },
        {
            id: 'Description',
            header: 'Description',
            cell: (item: RxNormTableProps) => item.Description
        },
        {
            id: 'Score',
            header: 'Confidence',
            cell: (item: RxNormTableProps) => (`${(item.Score * 100).toFixed(2)}%`)
        }

    ]

    return (
        <div style={{ padding: 5, height: window.innerHeight * 0.8, overflow: 'auto', }}>
            {RxNormData ? <Table
                columnDefinitions={columnDefinitions}
                items={RxNormData}
                stickyHeader
                //wrapLines
                variant="stacked"
            /> : <Spinner />}
        </div>
    )
}

export default RxNorm;