/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

import { Routes, Route } from "react-router-dom";
import { routeNames, routes } from '../../common/route';

// pages import 
import NotFound from "./NotFound";
import Home from './Home';
import Insurance from './Insurance';
import Mortgage from './Mortgage';
import PublicSector from './PublicSector'
import Healthcare from "./HealthCare";
// 
import AnalyzeClaim from './AnalyzeClaim';
import AnalyzeMortgage from "./AnalyzeMortgage";
import AnalyzeHealthcareClaim from "./AnalyzeHealthcareClaim";
import AnalyzePublicClaim from "./AnalyzePublicClaim"
import Reporting from "./Reporting";

const Content = () => {

    return (
        <Routes>
            <Route path={routes[routeNames.Home].href} element={<Home />} />
            <Route path={routes[routeNames.Insurance].href} element={<Insurance />} />
            <Route path={routes[routeNames.Mortgage].href} element={<Mortgage />} />
            <Route path={routes[routeNames.PublicSector].href} element={<PublicSector />} />
            <Route path={routes[routeNames.Claim].href} element={<AnalyzeClaim />} />
            <Route path={routes[routeNames.MtApplication].href} element={<AnalyzeMortgage />} />
            <Route path={routes[routeNames.Healthcare].href} element={<Healthcare />} />
            <Route path={routes[routeNames.HealthcareClaim].href} element={<AnalyzeHealthcareClaim />} />
            <Route path={routes[routeNames.PublicClaim].href} element={<AnalyzePublicClaim />} />
            <Route path={routes[routeNames.Reporting].href} element={<Reporting/>} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

export default Content;