/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { AppInterface, AppEnums } from '@aws-prototype/shared'

export const CLAIM_STATUS_MESSAGE: AppInterface.ClaimStatus[] = [
    {
        type: AppEnums.ClaimStates.PENDING_REVIEW,
        status: AppEnums.Status.PENDING,
        header: 'New Claim',
        content: 'Start a new claim verification.',
    },
    {
        type: AppEnums.ClaimStates.AUTO_APPROVED,
        status: AppEnums.Status.SUCCESS,
        header: 'Auto Approved',
        content: 'Claim was auto approved.',
    },
    {
        type: AppEnums.ClaimStates.APPROVED,
        status: AppEnums.Status.SUCCESS,
        header: 'Approved',
        content: 'Claim was approved and no further actions needed.',
    },
    {
        type: AppEnums.ClaimStates.AUTO_REJECTED,
        status: AppEnums.Status.WARNING,
        header: 'Auto Rejected',
        content: 'Not all required documents uploaded.',
    },
    {
        type: AppEnums.ClaimStates.REJECTED,
        status: AppEnums.Status.ERROR,
        header: 'Rejected',
        content: 'Claim was rejected and no further actions needed',
    },
    {
        type: AppEnums.ClaimStates.IN_PROGRESS,
        status: AppEnums.Status.IN_PROGRESS,
        header: 'In Progress',
        content: 'Claim analyzer is processing this claim.',
    },

];

export const CLAIM_DOCS_STATUS_MESSAGE: AppInterface.ClaimDocStatus[] = [
    {
        status: AppEnums.Status.SUCCESS,
        header: 'Success',
        content: 'Document analysis was successful.'
    },
    {
        status: AppEnums.Status.WARNING,
        header: 'Review Required',
        content: 'Confidence score is beneath the set threshold. Manual verification is recommended.'
    },
    {
        status: AppEnums.Status.INFO,
        header: 'Manually Classified',
        content: 'Document was classified manually.'
    },

];

export const CLAIM_FEATURES: AppInterface.ClaimDocFeatures[] = [
    {
        claimFeature: AppEnums.ClaimFeatures.insurerID,
        featureValue: 'PH-123456',
        status: AppEnums.Status.SUCCESS,
    },
    {
        claimFeature: AppEnums.ClaimFeatures.address,
        featureValue: '-',
        status: AppEnums.Status.ERROR,
    },
    {
        claimFeature: AppEnums.ClaimFeatures.name,
        featureValue: 'John Doe',
        status: AppEnums.Status.SUCCESS,
    },
    {
        claimFeature: AppEnums.ClaimFeatures.claimAmount,
        featureValue: '$123.0',
        status: AppEnums.Status.SUCCESS,
    }
];

export const CLAIM_FEATURES_STATUS_MESSAGE = [
    {
        status: AppEnums.Status.SUCCESS,
        header: 'Success',
        content: 'Data extraction was successful.'
    },
    {
        status: AppEnums.Status.ERROR,
        header: 'Additional Review Required',
        content: 'Value above set threshold. Please reach to customer to get this document updated.'
    },
];
