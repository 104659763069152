/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import React, { useEffect, useState } from "react";
import { AppEnums, AppInterface } from '@aws-prototype/shared';

//polaris
import { Table, TableProps, Header, StatusIndicator, Spinner, Link } from "@amzn/awsui-components-react/polaris";

// React Query
import { useQueryClient, } from "react-query";

// Lightbox
import Lightbox from 'react-image-lightbox';

// utility function
import { getDocumentPreviewUrl } from '../../../utils';

//components
import Info from '../Info';


interface RulesTableProps extends AppInterface.RuleResult {
    nextSteps?: string;
}

interface BusinessRulesProps {
    tableTitle: string;
    showConfidence?: boolean;
    items?: Array<AppInterface.RuleResult>;
}

const stepConfiguration = [
    {
        sortKey: "BR1",
        nextStep: "Store claim ID with claim form information on downstream database",
    },
    {
        sortKey: "BR2",
        nextStep: "Store claim ID with claim form information on downstream patient information database",
    },
    {
        sortKey: "BR3",
        nextStep: "Store in downstream database"
    },
    {
        sortKey: "BR4",
        nextStep: "None"
    },
    {
        sortKey: "BR5",
        nextStep: "Amount found. Ready for Claim reviewer",
    },
    {
        sortKey: "BR6",
        nextStep: "Tracking system integration",
    },
    {
        sortKey: "BR7",
        nextStep: "Send to downstream clinical repository",
    }
];

const BusinessRules = (props: BusinessRulesProps) => {
    const queryClient = useQueryClient();

    //props
    const { tableTitle, showConfidence, items } = props;

    // lightbox states
    const [lightBox, setLightBox] = useState({
        show: false,
        imageUrl: 'https://a0.awsstatic.com/libra-css/images/logos/aws_logo_smile_1200x630.png',
    })

    const docData = queryClient.getQueriesData("idp-insurance-docs")[0][1] as Array<AppInterface.ClaimDoc>;

    // table column definitions

    const columnDefinitions: TableProps.ColumnDefinition<RulesTableProps>[] = React.useMemo(() => [
        {
            id: 'ruleDescription',
            header: 'Rule',
            cell: (item: RulesTableProps) => item.ruleDescription
        },
        {
            id: 'document',
            header: 'Document',
            cell: (item: RulesTableProps) => (<>
                {item.documents && item.documents.map<React.ReactNode>((document: AppInterface.Key) =>
                    <Link key={document.sortKey} onFollow={async () => {
                        setLightBox({
                            show: true,
                            imageUrl: await getDocumentPreviewUrl(docData.find((doc: AppInterface.ClaimDoc) => doc.sortKey === document.sortKey)!.uploadLocation)
                        })
                    }}>
                        {docData.find((doc: AppInterface.ClaimDoc) => doc.sortKey === document.sortKey)?.documentType.Name}
                    </Link>
                ).reduce((previous, current) => [previous, ', ', current])}
            </>),
            minWidth: 200,
        },
        {
            id: 'ruleValue',
            header: 'Value',
            // cell: (item: RulesTableProps) => (Array.isArray(item.ruleValue) ? <ColumnLayout columns={1}>
            //     {item.ruleValue.map((value: any) => <div key={value}>{value}</div>)}
            // </ColumnLayout> : item.ruleValue),
            cell: (item: RulesTableProps) => Array.isArray(item.ruleValue) ? item.ruleValue.join(', ') : item.ruleValue,
            minWidth: 100,
        },
        {
            id: 'ruleStatus',
            header: 'Rule Status',
            cell: (item: RulesTableProps) => (<StatusIndicator type={item.ruleStatus === AppEnums.RuleStatus.PASS ? 'success' : 'error'} />)
        },
    ], [docData]);

    const [colDefinition, setColDefinition] = useState<TableProps.ColumnDefinition<RulesTableProps>[]>(columnDefinitions);

    useEffect(() => {
        if (showConfidence) {
            const modified = [
                ...columnDefinitions.slice(0, 3),
                {
                    id: 'confidenceThreshold',
                    header: 'Confidence Threshold',
                    cell: (item: RulesTableProps) => (item.confidence ? `${(item.confidence.threshold).toFixed(2)}%` : '-'),
                    minWidth: 120,
                },
                {
                    id: 'confidenceActual',
                    header: 'Actual Confidence',
                    cell: (item: RulesTableProps) => (item.confidence ? `${(item.confidence.actual).toFixed(2)}%` : '-'),
                    minWidth: 120,
                },
                ...columnDefinitions.slice(3)
            ];
            // modified.push(
            //     {
            //         id: 'nextSteps',
            //         header: 'Next Steps',
            //         cell: (item: RulesTableProps) => (stepConfiguration.find((step) => step.sortKey === item.sortKey)?.nextStep)
            //     },
            // )
            setColDefinition(modified);
        }
    }, [showConfidence, columnDefinitions]);

    return (
        <>
            {items ?
                <Table
                    header={<Header variant="h3" info={<Info />}>
                        {tableTitle}
                    </Header>}
                    columnDefinitions={colDefinition}
                    items={items}
                    wrapLines
                /> : <Spinner />}

            {lightBox.show && <Lightbox
                reactModalStyle={{overlay: {zIndex: 2000}}}
                mainSrc={lightBox.imageUrl}
                onCloseRequest={() => setLightBox({ ...lightBox, show: false })}
            />}

        </>
    )
}

export default BusinessRules;