import { useEffect, useState } from 'react';
import { Container, Header, LineChart } from '@amzn/awsui-components-react';
import {
  commonChartProps,
  dateTimeFormatter,
  lineChartInstructions,
} from './common';
import { useAtom, atom } from 'jotai';
import { batchIdAtom, endDateAtom, startDateAtom, timeSeriesDataAtom, yMaxAtom } from '@aws-prototype/shared';

const roundMinutes = (date: Date) => {
  date.setHours(date.getHours() + Math.round(date.getMinutes()/60));
  date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

  return date.toLocaleString();
}

interface dataType {
  title: string; type: "line"; valueFormatter: (value: number) => string; data: { x: Date; y: number; }[]
}

export default function TimeSeriesReporting() {
  const [batchId] = useAtom(batchIdAtom)
  const [timeSeriesData, ] = useAtom(timeSeriesDataAtom)
  const [startDate, ] = useAtom(startDateAtom)
  const [endDate, ] = useAtom(endDateAtom)
  const [yMax, setYMax] = useAtom(yMaxAtom)

  const [data, setData] = useState<{title: string, type: "line", 
    valueFormatter: (value: number) => string, data: {x: Date, y: number}[]}[]>([
      {
        title: `Batch ${batchId}`,
        type: "line",
        valueFormatter: (value: number) => value.toLocaleString('en-US'),
        data: []
      }
    ])

  const [domain, setDomain] = useState<Date[]>([])
  const addOneHour = (original_date: Date) => {
    return new Date(original_date.getTime() + 60*60000)
  }
  useEffect(() => {
    if(timeSeriesData) {
      console.log("timeSeriesData", timeSeriesData)
      const newData: dataType[] = [
        {
          title: `Batch ${batchId}`,
          type: "line",
          valueFormatter: (value: number) => value.toLocaleString('en-US'),
          data: Object.keys(timeSeriesData).map(key => {
            // console.log("key", key)
            const currentItemDate: Date = new Date(key)
            const currentItemCount: number = timeSeriesData[key].length
            if(currentItemCount > yMax) {
              setYMax(currentItemCount)
            }
            return {x: currentItemDate, y: currentItemCount}}).sort((a, b) => (
              a.x > b.x ? -1 : 1)
            )
        }
      ]
      setData(newData)
    }
    console.log(data)
    // if(data[0].data.length > 0) {
    //   setDomain([data[0].data[0].x, data[0].data[1].x])
    // }
    console.log(startDate, endDate)

    setDomain([addOneHour(startDate), addOneHour(endDate)])
  }, [timeSeriesData, batchId])

  useEffect(() => {
    console.log("data used for ts", data)
  }, [data])

  useEffect(() => {
    setDomain([addOneHour(startDate), addOneHour(endDate)])
  }, [startDate, endDate])

  return (
    <Container
      className="custom-dashboard-container"
      header={
        <Header variant="h2" description="Documents Processed by date">
          Batches processed over time
        </Header>
      }
    >
      <LineChart
        {...commonChartProps}
        height={220}
        series={data}
        yDomain={[0, yMax*1.25]}
        // xDomain={[startDate, endDate]}
        xDomain={domain} // I cant get the x axis label to change when i change the duration. the data is right but the labels wrong
        xScaleType="time"
        xTitle="Time (UTC)"
        yTitle="Documents processed"
        ariaLabel="Documents processed"
        ariaDescription={`Line chart showing documents processed across batches. ${lineChartInstructions}`}
        i18nStrings={{
          ...commonChartProps.i18nStrings,
          filterLabel: 'Filter displayed batches',
          filterPlaceholder: 'Filter batches',
          xTickFormatter: dateTimeFormatter,
        }}
      />
    </Container>
  );
}