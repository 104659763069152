/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { SideNavigationProps } from '@amzn/awsui-components-react';

const APP_NAME = 'IDP Demo';

export enum routeNames {
    Home = 'Home',
    Insurance = 'Insurance',
    Mortgage = 'Mortgage',
    Healthcare = 'Healthcare',
    Claim = 'Claim',
    MtApplication = 'Mortgage Application',
    HealthcareClaim = 'HealthcareClaim',
    PublicSector = 'PublicSector',
    PublicClaim = 'PublicClaim',
    Reporting="Reporting"
}

export const routes = {
    [routeNames.Home]: {
        text: routeNames.Home,
        href: '/',
    },
    [routeNames.Insurance]: {
        text: routeNames.Insurance,
        href: '/insurance',
    },
    [routeNames.Mortgage]: {
        text: routeNames.Mortgage,
        href: '/mortgage',
    },
    [routeNames.Healthcare]: {
        text: routeNames.Healthcare,
        href: '/healthcare',
    },
    [routeNames.PublicSector]: {
        text: routeNames.PublicSector,
        href: '/publicsector', // TODO: Make this scalable 
    },       
    [routeNames.Claim]: {
        text: routeNames.Claim,
        href: '/insurance/claim/:claimid',
    },
    [routeNames.HealthcareClaim]: {
        text: routeNames.HealthcareClaim,
        href: '/healthcare/claim/:claimid',
    },    
    [routeNames.MtApplication]: {
        text: routeNames.MtApplication,
        href: '/mortgage/application/:appid',
    },
    [routeNames.PublicClaim]: {
        text: routeNames.PublicClaim,
        // TODO: Make this scalable 
        href: '/publicsector/application/:claimid',
    },
    [routeNames.Reporting]: {
        text: routeNames.Reporting,
        href: '/reporting',
    },
}

export const navItems: SideNavigationProps.Item[] = [
    { type: "link", text: routes[routeNames.Home].text, href: routes[routeNames.Home].href },
    {
        type: 'section',
        text: 'Industry Use Case',
        items: [
            { type: 'link', text: routes[routeNames.Insurance].text, href: routes[routeNames.Insurance].href },
            { type: 'link', text: routes[routeNames.Mortgage].text, href: routes[routeNames.Mortgage].href },
            { type: 'link', text: routes[routeNames.Healthcare].text, href: routes[routeNames.Healthcare].href },
            { type: 'link', text: routes[routeNames.PublicSector].text, href: routes[routeNames.PublicSector].href },
        ]
    },

];

export const adminNavItems: SideNavigationProps.Item[] = [
    {
        type: 'section',
        text: 'Admin Controls',
        items: [
            { type: 'link', text: 'Demo Setup', href: '#/howto' },
            { type: 'link', text: 'User Setup', href: '#/origin' },
            { type: 'link', text: routes[routeNames.Healthcare].text, href: routes[routeNames.Healthcare].href },
        ]
    }
];

export const navHeader = { text: APP_NAME, href: routes[routeNames.Home].href }; 