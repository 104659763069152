/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

import { useState, useEffect } from 'react';
// Polaris Components
import {
    Button, Header,
    Table, Box, TableProps, Link,
} from '@amzn/awsui-components-react';

// interface 
import { AppInterface, AppConfigFeatures } from '@aws-prototype/shared';

// react query
import { useQueryClient } from "react-query";

// Lightbox
import Lightbox from 'react-image-lightbox';
import A2i from '../../../assets/a2i.png';

// redux
import { useDispatch } from 'react-redux'

// utility function
import { getDocumentPreviewUrl } from '../../../utils';

// store 
import { useAppSelector, RootState } from '../../../store';
import { ClaimClassifySliceType } from '../../../store/features/claim-classify-feature';

// components
import DocumentClassificationSelector from './DocumentClassificationSelector';
import DocumentClassificationStatus from './DocumentClassificationStatus';

interface DocumentClassifyProps {
    useCase: string
}

const DocumentClassify = (props: DocumentClassifyProps) => {
    const dispatch = useDispatch();
    // RQ cache
    const queryClient = useQueryClient();
    const data = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as Array<AppInterface.ClaimDoc>);
    // lightbox states
    const [lightBox, setLightBox] = useState({
        show: false,
        imageUrl: 'https://a0.awsstatic.com/libra-css/images/logos/aws_logo_smile_1200x630.png',
    })

    const classificationOverrides: Array<ClaimClassifySliceType> =
        useAppSelector((state: RootState) => state.claimClassify);
    
        console.count(props.useCase);
    const columnDefinitions: TableProps.ColumnDefinition<AppInterface.ClaimDoc>[] = [
        {
            id: 'docType',
            sortingField: 'docType',
            header: 'Document Classification',
            cell: (item: AppInterface.ClaimDoc) =>
                <DocumentClassificationSelector docType={item.documentType.Name} useCase={props.useCase} 
                            confidence={classificationOverrides.find((overrideItem: ClaimClassifySliceType) =>
                                overrideItem.type === item.documentType.Name) ? 'N/A' : `${((item.documentType.Score || 0) * 100).toFixed(2)} %`}
                />,
            maxWidth: 160
        },
        {
            id: 'preview',
            sortingField: 'preview',
            header: 'Preview',
            cell: (item: AppInterface.ClaimDoc) => <Link
                external
                externalIconAriaLabel="Opens in a new tab"
                onFollow={async () => {
                    setLightBox({
                        show: true, imageUrl: await getDocumentPreviewUrl(item.uploadLocation)
                    })
                }}
            >
                Document {data.findIndex(dataItem => dataItem.documentType.Name === item.documentType.Name) + 1}
            </Link>,
            maxWidth: 110
        },

        {
            id: 'fileType',
            sortingField: 'fileType',
            header: 'File Type',
            cell: (item: AppInterface.ClaimDoc) => item.sortKey.split('.').pop(),
            maxWidth: 60,
        },
        {
            id: 'size',
            sortingField: 'size',
            header: 'File Size',
            cell: (item: AppInterface.ClaimDoc) => item.fileSize,
            maxWidth: 80,

        },
        {
            id: 'confidence',
            sortingField: 'confidence',
            header: 'Confidence',
            cell: (item: AppInterface.ClaimDoc) => classificationOverrides.find((overrideItem: ClaimClassifySliceType) =>
                overrideItem.type === item.documentType.Name) ? 'N/A' : `${((item.documentType.Score || 0) * 100).toFixed(2)} %`,
            maxWidth: 95,
        },
        {
            id: 'status',
            sortingField: 'status',
            header: 'Status',
            cell: (item: AppInterface.ClaimDoc) => (<DocumentClassificationStatus confidence={classificationOverrides
                .find((overrideItem: ClaimClassifySliceType) =>
                    overrideItem.type === item.documentType.Name) ? 0 : (item.documentType.Score || 0)} />),
            maxWidth: 135
        },
        {
            id: 'createdOn',
            sortingField: 'createdOn',
            header: 'Created On',
            cell: (item: AppInterface.ClaimDoc) => item.uploadedAt,
            maxWidth: 100
        },
    ];

    useEffect(() => {
        dispatch(AppConfigFeatures.setHelpKey(2));
    }, [dispatch])

    return (
        <>
            <Table
                header={<Header variant="h3"
                    counter={data ? `(${data.length.toString()})` : '-'}
                    actions={
                        <Button variant='primary' onClick={() => setLightBox({
                            show: true,
                            imageUrl: A2i,
                        })}  >
                            Human Review
                        </Button>
                    }
                >
                    {
                        (props.useCase === "claim")?"Claim Documents":null
                    }
                    {
                        (props.useCase === "mortgage")?"Mortgage Documents":null
                    }
                    {
                        (props.useCase === "healthcare")?"Healthcare Documents":null
                    }
                    {
                        (props.useCase === "publicsector")?"Application Documents":null
                    }
                </Header>
                }
                columnDefinitions={columnDefinitions}
                items={data}
                sortingDisabled
                variant="embedded"
                stickyHeader={true}
                wrapLines
                empty={
                    <Box textAlign="center" color="inherit">
                        <Box
                            padding={{ bottom: "s" }}
                            variant="p"
                            color="inherit"
                        >
                            No Claim Documents to display.
                        </Box>

                    </Box>
                }
            />

            {lightBox.show && <Lightbox 
                reactModalStyle={{overlay: {zIndex: 2000}}}
                mainSrc={lightBox.imageUrl}
                onCloseRequest={() => setLightBox({ ...lightBox, show: false })}
            />}

        </>
    )
}

export default DocumentClassify;