/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect, useState } from "react";
import { AppEnums, AppInterface } from '@aws-prototype/shared';

//polaris
import { Table, } from "@amzn/awsui-components-react/polaris";

// React Query
import { useQueryClient, } from "react-query";

// utils
import { getDocumentAsJSON } from '../../../../utils';

interface LineItemProps {
    docType: AppEnums.ClaimDocTypes | AppEnums.HealthcareDocTypes;
}

interface DataItem {
    [key: string]: string,

}
interface TableDataProps {
    header: Array<string>,
    data: Array<DataItem>
}


const EXPENSE_ANALYSIS_KEY = 'textractExpenseAnalysisOutput';

const LineItems = (props: LineItemProps) => {
    //props
    const { docType } = props;
    const queryClient = useQueryClient();

    // states
    const [tableData, setTableData] = useState<TableDataProps>();

    // retrieve data from RQ cache
    const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as
        Array<AppInterface.ClaimDoc>).find(item => item.documentType.Name === docType);

    useEffect(() => {
        if (docType && docData && docData[EXPENSE_ANALYSIS_KEY]) {
            const getAnalyzeInvoice = async () => {
                const analyzeInvoiceResponse: any = await getDocumentAsJSON(docData[EXPENSE_ANALYSIS_KEY] as string);
                const LineItemsExpenseFields = analyzeInvoiceResponse.ExpenseDocuments[0].LineItemGroups[0].LineItems.map((lineItem: any) =>
                    lineItem.LineItemExpenseFields);
                // get columnDefinitions
                setTableData(LineItemsExpenseFields.reduce((acc: any, expenseItems: any) => {
                    acc.data.push(expenseItems.filter((ef: any) => ef.Type.Text !== "EXPENSE_ROW").reduce((dataAcc: DataItem, expenseItem: any) => {
                        acc.header.add(expenseItem.LabelDetection.Text)
                        // create data entry for the tables
                        return {
                            ...dataAcc,
                            [expenseItem.LabelDetection.Text]: expenseItem.ValueDetection.Text,
                        };
                    }, {} as DataItem));

                    return acc;
                }, {
                    header: new Set<string>(),
                    data: [],
                }))
            }

            if (docData && docData.documentType.Name === docType) {
                getAnalyzeInvoice();
            }
        }
    }, [docData, docType]);

    return (
        <div style={{ padding: 5, height: window.innerHeight * 0.8, overflow: 'auto', }}>
            {tableData &&
                <Table
                    columnDefinitions={
                        Array.from(tableData.header).map((headerItem: string) => ({
                            id: headerItem,
                            header: headerItem,
                            cell: item => item[headerItem],
                        }))
                    }
                    items={tableData.data}
                />}
        </div>
    )
}

export default LineItems;