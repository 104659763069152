/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect } from 'react';

// shared imports
import { AppEnums, AppConfigFeatures } from '@aws-prototype/shared';

import { SpaceBetween, ExpandableSection } from '@amzn/awsui-components-react';

// redux
import { useDispatch } from 'react-redux'

// Components import
import BasicEntities from './BasicEntities';
import CustomEntities from './CustomEntities';
import ExtractFeature from '../extract/ExtractFeature';
import Query from '../extract/tabs/Query';
import Lines from '../extract/tabs/Lines';
import HumanReview from '../HumanReview';

const docTypesValuesEnum = {
    ...AppEnums.ClaimDocTypesValues,
    ...AppEnums.MortgageDocTypesValues,
    ...AppEnums.ApplicationDocTypesValues,
 }

export const extractPageConfig = [
    {
        docType: AppEnums.ClaimDocTypes.DISCHARGE_SUMMARY,
        tabs: [

            {
                label: "Key Business Terms",
                id: "custom-entities",
                content: <CustomEntities docType={AppEnums.ClaimDocTypes.DISCHARGE_SUMMARY} />,
            },
            {
                label: "Predefined Entities",
                id: "entities",
                content: <BasicEntities docType={AppEnums.ClaimDocTypes.DISCHARGE_SUMMARY} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
];

export const extractMortgagePageConfig = [
    {
        docType: AppEnums.MortgageDocTypes.HOA_STMT,
        tabs: [
            {
                label: "Queries",
                id: "queries",
                content: <Query docType={AppEnums.MortgageDocTypes.HOA_STMT} />,
            },            
            {
                label: "Key Business Terms",
                id: "key-entities",
                content: <CustomEntities docType={AppEnums.MortgageDocTypes.HOA_STMT} />,
            },
            {
                label: "Predefined Entities",
                id: "entities",
                content: <BasicEntities docType={AppEnums.MortgageDocTypes.HOA_STMT} />,
            },
            // {
            //     label: "Human Review",
            //     id: "human_review",
            //     content: <HumanReview />,
            // }
        ],
    },
    {
        docType: AppEnums.MortgageDocTypes.MORTGAGE_NOTE,
        tabs: [
            {
                label: "Queries",
                id: "queries",
                content: <Query docType={AppEnums.MortgageDocTypes.MORTGAGE_NOTE} />,
            },
            {
                label: "Lines",
                id: "lines",
                content: <Lines docType={AppEnums.MortgageDocTypes.MORTGAGE_NOTE} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    }
];

export const extractHealthcarePageConfig = [
    {
        docType: AppEnums.ClaimDocTypes.DISCHARGE_SUMMARY,
        tabs: [

            {
                label: "Key Business Terms",
                id: "custom-entities",
                content: <CustomEntities docType={AppEnums.ClaimDocTypes.DISCHARGE_SUMMARY} />,
            },
            {
                label: "Predefined Entities",
                id: "entities",
                content: <BasicEntities docType={AppEnums.ClaimDocTypes.DISCHARGE_SUMMARY} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
];

export const extractPagePublicSectorConfig = [
    {
        docType: AppEnums.ApplicationDocTypes.APPLICATION_FORM,
        tabs: [

            {
                label: "Key Business Terms",
                id: "custom-entities",
                content: <CustomEntities docType={AppEnums.ApplicationDocTypes.APPLICATION_FORM} />,
            },
            {
                label: "Predefined Entities",
                id: "entities",
                content: <BasicEntities docType={AppEnums.ApplicationDocTypes.APPLICATION_FORM} />,
            },
            {
                label: "Human Review",
                id: "human_review",
                content: <HumanReview />,
            }
        ],
    },
];


interface ExtractEntitiesProps {
    useCase: string
}

const ExtractEntities = (props: ExtractEntitiesProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AppConfigFeatures.setHelpKey(4));
    }, [dispatch])

    return (
        <SpaceBetween size="l">
            {
                (props.useCase === "claim")?
                extractPageConfig.map((feature,index) =>
                    <>{
                        (extractPageConfig.length>1)?
                            <>
                                <ExpandableSection                                                                                
                                            header={docTypesValuesEnum[feature.docType]} 
                                            disableContentPaddings 
                                            variant='footer' 
                                            defaultExpanded={(index===0)?true:false}
                                        >
                                    <ExtractFeature
                                        key={feature.docType}
                                        docType={feature.docType}
                                        tabs={feature.tabs} />
                                </ExpandableSection>
                                <hr className='custom-hr'/>
                            </>
                            :<ExtractFeature
                                key={feature.docType}
                                docType={feature.docType}
                                tabs={feature.tabs} />
                        }
                    </>
                )
                :null
            }
            {   (props.useCase === "mortgage")?
                extractMortgagePageConfig.map((feature,index) =>
                    <>
                        <ExpandableSection                                                                                
                                    header={docTypesValuesEnum[feature.docType]} 
                                    disableContentPaddings 
                                    variant='footer' 
                                    defaultExpanded={(index===0)?true:false}
                                >
                            <ExtractFeature
                                key={feature.docType}
                                docType={feature.docType}
                                tabs={feature.tabs} />
                        </ExpandableSection>
                        <hr className='custom-hr'/>
                    </>
                )
                :null
            }
            {
                (props.useCase === "healthcare")?
                extractPageConfig.map((feature,index) =>
                    <>{
                        (extractPageConfig.length>1)?
                            <>
                                <ExpandableSection                                                                                
                                            header={docTypesValuesEnum[feature.docType]} 
                                            disableContentPaddings 
                                            variant='footer' 
                                            defaultExpanded={(index===0)?true:false}
                                        >
                                    <ExtractFeature
                                        key={feature.docType}
                                        docType={feature.docType}
                                        tabs={feature.tabs} />
                                </ExpandableSection>
                                <hr className='custom-hr'/>
                            </>
                            :<ExtractFeature
                                key={feature.docType}
                                docType={feature.docType}
                                tabs={feature.tabs} />
                        }
                    </>
                )
                :(props.useCase === "publicsector") ? 
                extractPagePublicSectorConfig.map((feature,index) =>
                <>{
                    (extractPagePublicSectorConfig.length>1)?
                        <>
                            <ExpandableSection                                                                                
                                        header={docTypesValuesEnum[feature.docType]} 
                                        disableContentPaddings 
                                        variant='footer' 
                                        defaultExpanded={(index===0)?true:false}
                                    >
                                <ExtractFeature
                                    key={feature.docType}
                                    docType={feature.docType}
                                    tabs={feature.tabs} />
                            </ExpandableSection>
                            <hr className='custom-hr'/>
                        </>
                        :<ExtractFeature
                            key={feature.docType}
                            docType={feature.docType}
                            tabs={feature.tabs} />
                    }
                </>
            )
                :null
            }


        </SpaceBetween>
    )
}

export default ExtractEntities;


