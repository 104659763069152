/**********************************************************************************************************************
 *  Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { SelectProps } from '@amzn/awsui-components-react';
import { atom } from 'jotai';

// default to an empty array since there is by default no data
export const SelectedOptionAtom = atom<SelectProps.Option>({ label: 'UNKNOWN', value: 'Unknown Type' });

export const NewClassificationNameAtom = atom('Tenancy Agreement');

export const envelopeHighPriorityAtom = atom(false);
