/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

// Polaris
import { Box, Grid, SpaceBetween, Header } from '@amzn/awsui-components-react';
import '../../styles/home.scss';

// store 
import { useAppSelector, RootState } from '../../store';

// components import 
import Info from '../components/Info';
import IndustryCard from '../components/IndustryCard';

// interfaces
import { AppInterface } from '@aws-prototype/shared'

const Home = () => {
    const Industries: AppInterface.Industry[] = useAppSelector((state: RootState) => state.industries);
    return (
        <>
            <Box margin={{ bottom: 'l' }}>
                <div className="custom-home__header">
                    <Box padding={{ vertical: 'xxxl', horizontal: 'xxxl' }}>
                        <SpaceBetween size='m'>
                            {/* <Box fontWeight="light" padding={{ top: 'xs' }}>
                                <span className="custom-home__category">AWS Prototyping</span>
                            </Box> */}
                            <div className="custom-home__header-title">
                                <SpaceBetween size='m'>
                                <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                                {/* <Header variant='h1' info={<Info />}> */}
                                    Intelligent Document Processing <Info />
                                {/* </Header> */}
                                </Box>
                                <Box variant="h4" fontWeight="heavy" padding="n" fontSize="heading-s" color="inherit">
                                    Improve employee productivity and make faster decisions with intelligent document processing. 
                                </Box>
                                <p style={{fontSize: '14px'}}>
                                    Documents come in various file types, varied formats, and contain valuable information. In most cases, you are manually processing the documents which is time consuming, prone to error, and costly. Not only do you want this information quickly but likely need to use the information within those documents for downstream applications.
                                    To help overcome these challenges, AWS Machine Learning (ML) now provides you choices when it comes to extracting information from complex content in any document format such as insurance claims, mortgages, healthcare claims, contracts, and legal contracts. 
                                </p>
                                </SpaceBetween>
                            </div>
                        </SpaceBetween>
                    </Box>
                </div>
            </Box>
            <div className='custom-home__content'>
                <Box padding={{ horizontal: 'xxxl' }}>
                    <SpaceBetween size='xl'>
                        <Header
                            variant="h2"
                        >
                            Industry Use Case
                        </Header>
                        <Grid
                        // Responsive much?....
                            gridDefinition={[
                                { colspan: { default: 6, xxs: 6 } }, 
                                { colspan: { default: 6, xxs: 6 } }, 
                                { colspan: { default: 6, xxs: 6 } },
                                { colspan: { default: 6, xxs: 6 } },
                            ]}                        
                        >
                            {Industries.map((industry: AppInterface.Industry, index: number) =>
                                <IndustryCard key={`icd-${index}`} industry={industry} />)}
                        </Grid>
                    </SpaceBetween>
                </Box>
            </div>
        </>
    )
}

export default Home;