/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
// store 
import { useAppSelector, RootState } from '../../store';

// wizard Help Components
import DocumentCaptureHelp from './wizard/DocumentCaptureHelp';
import DocumentClassificationHelp from './wizard/DocumentClassificationHelp';
import DocumentExtractionHelp from './wizard/DocumentExtractionHelp';
import DocumentEntityExtractHelp from './wizard/DocumentEntityExtractHelp';
import DocumentEnrichHelp from './wizard/DocumentEnrichHelp';
import ReviewHelp from './wizard/ReviewHelp';

const AnalyzeClaimHelp = () => {
    const helpKey: number = useAppSelector((state: RootState) => state.config.helpKey);

    return (
        <>
            {helpKey === 1 && <DocumentCaptureHelp />}
            {helpKey === 2 && <DocumentClassificationHelp />}
            {helpKey === 3 && <DocumentExtractionHelp />}
            {helpKey === 4 && <DocumentEntityExtractHelp />}
            {helpKey === 5 && <DocumentEnrichHelp />}
            {helpKey === 6 && <ReviewHelp />}
        </>
    )
}


export default AnalyzeClaimHelp;