/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect, useState } from "react";
import { AppEnums, AppInterface } from '@aws-prototype/shared';

// React Query
import { useQueryClient, } from "react-query";

// utils
import { getDocumentAsJSON } from '../../../../utils';

// components
import KeyPair from '../../KeyPair';

interface SummaryFieldsProps {
    docType: AppEnums.ClaimDocTypes | AppEnums.HealthcareDocTypes;
}

const EXPENSE_ANALYSIS_KEY = 'textractExpenseAnalysisOutput';

const SummaryFields = (props: SummaryFieldsProps) => {
    //props
    const { docType } = props;
    const queryClient = useQueryClient();

    // states
    const [summaryFields, setSummaryFields] = useState<AppInterface.KeyValue[]>([]);

    // retrieve data from RQ cache
    const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as
        Array<AppInterface.ClaimDoc>).find(item => item.documentType.Name === docType);

    useEffect(() => {
        if (docType && docData && docData[EXPENSE_ANALYSIS_KEY]) {
            const getAnalyzeInvoice = async () => {
                const analyzeInvoiceResponse: any = await getDocumentAsJSON(docData[EXPENSE_ANALYSIS_KEY] as string);
                setSummaryFields(analyzeInvoiceResponse.ExpenseDocuments[0].SummaryFields.filter((field: any) => field.LabelDetection && field.LabelDetection.Text)
                    .map((item: any) => ({
                        key: item.LabelDetection.Text,
                        value: item.ValueDetection.Text,
                        confidence: item.Type.Confidence,
                    })));
            }

            if (docData && docData.documentType.Name === docType) {
                getAnalyzeInvoice();
            }
        }
    }, [docData, docType]);

    return (
        <div style={{ display: 'flex', padding: 10, height: window.innerHeight * 0.8, overflow: 'auto', }}>
            <KeyPair data={summaryFields} form />
        </div>
    )
}

export default SummaryFields;