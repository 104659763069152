/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
// Polaris Components
import {
    AppLayout,
    Alert,
    Table,
    Header,
    TableProps,
    Link,
    BreadcrumbGroup,
    BreadcrumbGroupProps,
    Box
} from '@amzn/awsui-components-react';

// router
import { useNavigate } from "react-router-dom";
import { routeNames, routes } from '../../common/route';

// interfaces
import { AppInterface, AppEnums } from '@aws-prototype/shared';

// components 
import Info from '../components/Info';
import ClaimStatusIndicator from '../components/ClaimStatusIndicator';

// React query  
import { useClaim } from '../../hooks/useClaim';
import { useWindowDimensions } from '../../hooks/useWindowDiemnsions';

const Insurance = () => {
    const navigate = useNavigate();

    // RQ custom hook
    const { data, isError, isFetching } = useClaim('Claim'); // get claims     
    const { width } = useWindowDimensions();

    const gotoPage = (event: any) => {
        event.preventDefault();
        navigate(event.detail.href)
    }

    // table column definitions
    const columnDefinitions: TableProps.ColumnDefinition<AppInterface.Claim>[] = [
        {
            id: 'sortKey',
            sortingField: 'sortKey',
            header: 'Claim ID',
            cell: (item: AppInterface.Claim) => (item.status === AppEnums.ClaimStates.PENDING_REVIEW ? <Link onFollow={
                () =>
                    navigate(routes[routeNames.Claim].href.replace(':claimid', item.sortKey))

            }>{item.sortKey}</Link> : item.sortKey),
            minWidth: 250
        },
        {
            id: 'claimType',
            sortingField: 'claimType',
            header: 'Claim Type',
            cell: (item: AppInterface.Claim) => item.claimType,
            minWidth: 100
        },
        {
            id: 'claimAmount',
            sortingField: 'claimAmount',
            header: 'Claim Amount',
            cell: (item: AppInterface.Claim) => item.claimAmount,
            minWidth: 100
        },
        {
            id: 'dateFiled',
            sortingField: 'dateFiled',
            header: 'Date Filed',
            cell: (item: AppInterface.Claim) => item.dateFiled,
            minWidth: 100
        },
        {
            id: 'status',
            sortingField: 'status',
            header: 'Status',
            cell: (item: AppInterface.Claim) => (<ClaimStatusIndicator status={item.status} />),
            minWidth: 100
        },
        {
            id: 'description',
            sortingField: 'description',
            header: 'Description',
            cell: (item: AppInterface.Claim) => (<ClaimStatusIndicator status={item.status} description />),
            minWidth: 100
        },
    ];


    return (
        <AppLayout
            contentType='wizard'
            minContentWidth={width - 300}
            // disableContentPaddings
            navigationHide={true}
            toolsHide={true}
            breadcrumbs={<BreadcrumbGroup
                items={[
                    { text: "Home", href: "/" },
                    { text: "Claims", href: "/insurance" }
                ]}
                ariaLabel="Breadcrumbs"
                expandAriaLabel="Show path"
                onFollow={(event: CustomEvent<BreadcrumbGroupProps.ClickDetail>) => gotoPage(event)}
            />}
            content={
                <Table
                    header={<Header variant="h3"    //awsui-h1-sticky                
                        counter={data && `(${data.length.toString()})`}
                        info={<Info />}
                    >
                        Claims
                    </Header>}
                    columnDefinitions={columnDefinitions}
                    items={data}
                    sortingDisabled
                    variant="full-page"
                    stickyHeader={true}
                    resizableColumns={true}
                    loading={isFetching}
                    loadingText="Loading insurance claims"
                    empty={
                        (isError) ?
                            <Alert type='error' header="Unable to fetch Claims">
                                We are unable to fetch the list of insurance claims at this time. Please try again later.
                            </Alert>
                            : <Box textAlign="center" color="inherit">
                                <b>No insurance claims to display</b>
                            </Box>
                    }
                />
            }
            contentHeader={<Header variant="h1">Insurance - Case worker portal</Header>}
        />
    )
}

export default Insurance;