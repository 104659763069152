/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useState, useEffect } from 'react';

// Canvas
import { Stage, Layer, Image as CanvasImage, Line } from 'react-konva';
import useImage from 'use-image';

// shared 
import { useDispatch } from 'react-redux'
import { AppInterface, ExtractFeatures } from '@aws-prototype/shared';

// store 
import { RootState, useAppSelector } from '../../store';

// utils
import { getDocumentPreviewUrl } from '../../utils';


interface DocumentCanvasProps {
    docData: AppInterface.ClaimDoc | AppInterface.HealthcareDoc
}

const DocumentCanvas = (props: DocumentCanvasProps) => {
    const dispatch = useDispatch();

    // props
    const { docData } = props;
    const [imageUrl, setImageUrl] = useState('https://freesvg.org/img/Loading_icon_no_fade.png');
    const [imageSize, setImageSize] = useState({
        height: 120,
        width: 120,
    });
    const [pointsArray, setPointsArray] = useState<Array<number[]>>();

    // this hook can be used to get image from a URL
    const [image] = useImage(imageUrl);

    useEffect(() => {
        const getImageUrl = async () => {
            const url = await getDocumentPreviewUrl(docData.uploadLocation);
            setImageUrl(url);
            return url;
        }
        const img = new Image();
        // get preview url with image dimensions to set stage
        getImageUrl().then(url => {
            img.src = url;
            img.onload = () => {
                setImageSize({
                    height: img.height,
                    width: img.width,
                });
            };
        });

    }, [docData]);


    const extracts = useAppSelector((state: RootState) =>
        state.extract.find((item: ExtractFeatures.ExtractSliceInterface) => item.docType === docData.documentType.Name));

    const annotations = useAppSelector((state: RootState) => state.annotations);

    useEffect(() => {
        if (extracts.selectedTab && extracts.selectedTab === 'pii') {            
            const points = annotations.pii.points.map((item: AppInterface.Point[]) => item.flatMap((polygon: any) => [
                +polygon.x * imageSize.width, +polygon.y * imageSize.height
            ]));
            setPointsArray(points);

        }
    }, [annotations, extracts, imageSize]);

    return (
        <Stage width={window.innerWidth / 2} height={window.innerHeight * 0.8} style={{background: '#ECEFF1'}}>
            <Layer                
                draggable
                scale={{ x: extracts.scale, y: extracts.scale }}
                x={extracts.x}
                y={extracts.y}
                onDragEnd={(evt: any) => dispatch(ExtractFeatures.onDrag({
                    docType: docData.documentType.Name,
                    x: evt.target.x(),
                    y: evt.target.y(),
                }))}>
                <CanvasImage width={imageSize.width} height={imageSize.height} image={image} />
                {annotations.pii.show && pointsArray && pointsArray.map((point, index) =>
                    <Line
                        key={index}
                        points={point}
                        closed
                        stroke="black"
                        strokeWidth={2}
                        fill='black'
                    />
                )}
            </Layer>
        </Stage>
    )
}

export default DocumentCanvas;