/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect, useState } from "react";
import { AppEnums, AppInterface, AnnotationFeatures } from '@aws-prototype/shared';

// Polaris Components
import { Table, TableProps, Spinner, Header, Toggle, SpaceBetween, TextContent } from '@amzn/awsui-components-react';

// React Query
import { useQueryClient, } from "react-query";

// utils
import { getDocumentAsJSON } from '../../../utils';

// React Query
import { useClaimAnalyzeDoc } from '../../../hooks/useClaim';

// redux
import { useDispatch } from 'react-redux'


type docTypes = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.HealthcareDocTypes | AppEnums.ApplicationDocTypes

interface PIIProps {
    // docType: AppEnums.ClaimDocTypes;
    docType: docTypes
}

interface StringOffsets {
    BeginOffset: number;
    EndOffset: number;
}

interface PIIInference {
    Type: string;
    Score: number;

}

interface PIIResponse extends PIIInference, StringOffsets { }

interface PIITableProps extends PIIInference {
    Text: string;
    Redacted: string;
}

const PLAIN_TEXT = 'textractPlainTextOutput';
const COMP_CLASSIFICATION_KEY = 'comprehendPiiEntitiesOutput';

const PII = (props: PIIProps) => {
    const dispatch = useDispatch();

    // props
    const { docType } = props;
    const queryClient = useQueryClient();

    // toggle button
    const [checked, setChecked] = useState(false);

    // pii data
    const [PIITableData, setTableData] = useState<PIITableProps[]>()

    // retrieve data from RQ cache
    const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as
        Array<AppInterface.ClaimDoc>).find(item => item.documentType.Name === docType);

    // RQ Fetch
    const { data } = useClaimAnalyzeDoc(docData!.hashKey, docData!.sortKey, AppEnums.DocumentOutput.WORDS);

    useEffect(() => {
        const getPII = async () => {
            const plainText: string = await getDocumentAsJSON(docData![PLAIN_TEXT]!);
            const { Entities }: any = await getDocumentAsJSON(docData![COMP_CLASSIFICATION_KEY]!);            

            setTableData(Entities.map((item: PIIResponse) => ({
                Text: plainText.substring(item.BeginOffset, item.EndOffset),
                Redacted: '#'.repeat(plainText.substring(item.BeginOffset, item.EndOffset).length),
                Type: item.Type,
                Score: item.Score,
            })));

        }
        if (data) {
            getPII();

        }
    }, [docData, docType, data]);

    useEffect(() => {
        if (data && PIITableData && PIITableData.length > 0) {
            const piiWords = PIITableData.map((tableData: PIITableProps) => tableData.Text.split(' ')).flat();
            const points = (data.reduce((acc: Array<AppInterface.Point[]>, currentItem: AppInterface.TextractBase) => {
                if (piiWords.find((piiWord: string) => piiWord === currentItem.text)) {
                    acc.push(currentItem.polygon);
                }
                return acc;
            }, [] as Array<AppInterface.Point[]>));
            
            // set the PII points Array
            dispatch(AnnotationFeatures.setAnnotations({
                type: 'pii',
                polygons: points,
            }));

        }
    }, [data, PIITableData, dispatch]);

    // table column definitions
    const columnDefinitions: TableProps.ColumnDefinition<PIITableProps>[] = [
        {
            id: 'Text',
            header: 'Entity',
            cell: (item: PIITableProps) => (checked ? item.Redacted : item.Text)
        },
        {
            id: 'Type',
            header: 'Type',
            cell: (item: PIITableProps) => item.Type
        },
        {
            id: 'Score',
            header: 'Confidence',
            cell: (item: PIITableProps) => (`${(item.Score * 100).toFixed(2)}%`)
        }
    ]

    const handleToggle = (toggle: boolean) => {
        setChecked(toggle)
        if (toggle) {
            dispatch(AnnotationFeatures.showAnnotations('pii'))
        } else {
            dispatch(AnnotationFeatures.hideAnnotations('pii'))
        }
    }

    return (
        <div style={{ padding: 5, height: window.innerHeight * 0.8, overflow: 'auto', }}>
            <SpaceBetween size="l">
                {PIITableData ? <Table
                    header={<Header variant="h3"
                        actions={
                            <Toggle
                                onChange={({ detail }) => {
                                    handleToggle(detail.checked);
                                }
                                }
                                checked={checked}
                            >
                                Redact PII/PHI
                            </Toggle>
                        }>
                        Personally Identifiable Info
                    </Header>}
                    resizableColumns
                    columnDefinitions={columnDefinitions}
                    items={PIITableData}
                    // stickyHeader
                    wrapLines
                    variant="embedded"
                /> : <Spinner />}
                <TextContent>
                    <p><b>Note:</b> some of the items have been filtered for the demo</p>
                </TextContent>
            </SpaceBetween>
        </div>

    )
}

export default PII;

