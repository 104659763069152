/**********************************************************************************************************************
 *  Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { SelectProps } from '@amzn/awsui-components-react';
import { atom } from 'jotai';

// default to an empty array since there is by default no data

export const durationForDocumentsProcessedAtom = atom('YTD');

export const numberOfDocumentsAtom = atom(0);

export const averageTimePerDocumentAtom = atom(0);

export const timePerDocumentListAtom = atom([]);

export const averageConfidenceAtom = atom(0);

export const numberDocsManualRequiredAtom = atom(0);

export const averageSummary1PercentageAtom = atom<number>(0);

export const averageSummary2PercentageAtom = atom<number>(0);

export const pieChartDataAtom = atom<{ title: string; value: number; color: string }[]>([]);

export const selectedTimeFrameAtom = atom<SelectProps.Option>({ label: 'All Time', value: 'all' });

// export const batchIdAtom = atom<string>("44efbb67-1335-448f-b92b-ffbdab0bc481")
// export const yMaxAtom = atom<number>(16)
// export const startDateAtom = atom<Date>(new Date('2022-06-24 23:00'))
// export const endDateAtom = atom<Date>(new Date('2022-06-29 20:00'))

export const batchIdAtom = atom<string>('9358e6cb-24bb-4cf4-8181-3204cfdc0d65');
export const yMaxAtom = atom<number>(3);
export const startDateAtom = atom<Date>(new Date('2022-06-28 15:00'));
export const endDateAtom = atom<Date>(new Date('2022-07-30 13:00'));

export const timeSeriesDataAtom = atom<{ [key: string]: string[] }>({});
