/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { AppInterface } from '@aws-prototype/shared';
import insurImg from '../assets/health-insurance.jpeg';
import mrtgImg from '../assets/mortgage.jpeg';
import healthImg from '../assets/health-insur.jpeg';
import publicImg from '../assets/public_sector_slug.jpeg'

const Industries: AppInterface.Industry[] = [
    {
        hashKey: "Industry",
        sortKey: "0",
        name: 'Insurance',
        image: insurImg,
        description: 'Insurance claims and the communication around each claim means documents come in various channels and formats with long paragraphs of text or forms with applicant information proving a challenge to process the documents. By combining text extraction and NLP, you can process insurance forms such as CMS-1500, binders, ACORD forms, and claims forms faster, with higher accuracy to extract text from documents and understand the insight locked inside.',
        actionTitle: 'Case Worker Portal',   
        reportingHashKey: "9358e6cb-24bb-4cf4-8181-3204cfdc0d65"
    },
    {
        hashKey: "Industry",
        sortKey: "0",
        name: 'Mortgage',
        image: mrtgImg,
        description: "A mortgage packet can come with up to 20 different types of forms such as W-2's, bank statements, and deed information which makes it difficult to use traditional technologies to automate the process. Using Optical Character Recognition (OCR) and Natural Language Processing (NLP), you can automate the extraction of these documents whether they are structured forms like W-2’s or semi-structured documents like mortgage forms. Using AWS Artificial Intelligence (AI) you can now extract information from all types of documents",
        actionTitle: "Loan Processor Portal",
        reportingHashKey: "8562057552458"
    },
    {
        hashKey: "Industry",
        sortKey: "0",
        name: 'Healthcare',
        image: healthImg,
        description: "Medical documents come in various layouts, formats which can include a paragraph of text followed by a table or form elements, making it hard to use traditional Optical Character Recognition (OCR) software. Extract all the data from medical insurance claims, healthcare forms or patient records using artificial intelligence faster, with higher accuracy to extract text from documents and understand the insight locked inside.",
        actionTitle: "Healthcare Portal",
        reportingHashKey: "6242b1ca-f68a-4cb1-8ea5-9f679ab3339f"
    },
    {
        hashKey: "Industry",
        sortKey: "0",
        name: 'PublicSector',
        displayName: "Benefit Application",
        image: publicImg,
        description: "A benefit application involves multiple documents with various layouts. Processing these documents manually or using legacy OCR system is time-consuming and error-prone. Extracting and understanding the data from benefit application documents using artificial intelligence technology largely accelerate and simplify the application process, thus improve both case worker and applicant’s experience.",
        actionTitle: "Benefit Reviewer Portal",
        reportingHashKey: "e022511b-6959-4836-bd08-5c0a51791304"
    }
]

export default Industries;