/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

// Polaris Components
import { Link } from '@amzn/awsui-components-react';

import { useDispatch } from 'react-redux'

// interfaces
import { AppConfigFeatures } from '@aws-prototype/shared'

interface InfoProps {
    helpKey?: number
}


const Info = (props: InfoProps) => {
    const dispatch = useDispatch();

    const handleFollow = () => {
        if (props.helpKey) {
            dispatch(AppConfigFeatures.setHelpKey(props.helpKey));
        }
        dispatch(AppConfigFeatures.toggleHelp());

    }
    return (
        <Link onFollow={handleFollow} variant="info">Info</Link>
    )
}

export default Info;