/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect, useState } from "react";
import { AppEnums, AppInterface } from '@aws-prototype/shared';

// Polaris Components
import { Table, TableProps, Spinner, SpaceBetween, TextContent } from '@amzn/awsui-components-react';

// React Query
import { useQueryClient, } from "react-query";

// utils    
import { getDocumentAsJSON } from '../../../utils';

type docTypesEnumType = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.ApplicationDocTypes;

interface BasicEntitiesProps {
    docType: docTypesEnumType;
}

interface EntityTableProps {
    Text: string;
    Type: string;
    Score: number;
}

const BASIC_ENTITIES_KEY = 'comprehendEntitiesOutput';

const BasicEntities = (props: BasicEntitiesProps) => {

    //props
    const { docType } = props;
    const queryClient = useQueryClient();

    const [basicEntities, setBasicEntities] = useState<EntityTableProps[]>();

    // retrieve data from RQ cache
    const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as
        Array<AppInterface.ClaimDoc>).find(item => item.documentType.Name === docType);

    useEffect(() => {
        const getBasicEntities = async () => {
            const { Entities }: any = await getDocumentAsJSON(docData![BASIC_ENTITIES_KEY]!);
            setBasicEntities(Entities.filter((item: EntityTableProps) => !item.Text.includes('1950') &&
                !item.Type.includes('OTHER') && (item.Type.includes('PERSON') || item.Type.includes('DATE'))));
        }

        if (docType && docData && docData.documentType.Name === docType) {
            getBasicEntities();
        }

    }, [docData, docType]);

    // table column definitions
    const columnDefinitions: TableProps.ColumnDefinition<EntityTableProps>[] = [
        {
            id: 'Text',
            header: 'Entity',
            cell: (item: EntityTableProps) => item.Text
        },
        {
            id: 'Type',
            header: 'Type',
            cell: (item: EntityTableProps) => item.Type
        },
        {
            id: 'Score',
            header: 'Confidence',
            cell: (item: EntityTableProps) => (`${(item.Score * 100).toFixed(2)}%`)
        }
    ]
// console.log(basicEntities)
    return (
        <div style={{ padding: 5, height: window.innerHeight * 0.8, overflow: 'auto', }}>
            <SpaceBetween size="l">
                {basicEntities ? <Table                    
                    columnDefinitions={columnDefinitions}
                    items={basicEntities}
                    sortingDisabled
                    resizableColumns
                    wrapLines
                    variant="embedded"
                /> : <Spinner />}
                <TextContent>
                    <p><b>Note:</b> some of the items have been filtered for the demo</p>
                </TextContent>
            </SpaceBetween>
        </div>
    )

}
export default BasicEntities;