/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
 import { useEffect, useState } from "react";
 import { AppEnums, AppInterface } from '@aws-prototype/shared';
 
 // Polaris Components
 import { Table, TableProps, Spinner } from '@amzn/awsui-components-react';
 
 // React Query
 import { useQueryClient, } from "react-query";
 
 // utils
 import { getDocumentAsJSON } from '../../../utils';
import { isTemplateExpression } from "typescript";
import { iteratorSymbol } from "immer/dist/internal";

 
 interface MedicalConditionsProps {
    docType: AppEnums.HealthcareDocTypes;
}

interface MedicalConditionsTableProps {
    Text: string;
    Category: Text;
    Score: number;
}

const COMP_CLASSIFICATION_KEY = 'comprehendEntitiesOutput';

const MedicalConditions = (props: MedicalConditionsProps) => {

    //props
    const { docType } = props;
    const queryClient = useQueryClient();

    // state
    const [MedicalConditions, setMedicalConditions] = useState<MedicalConditionsTableProps[]>();


    // retrieve data from RQ cache
    const docData = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as
        Array<AppInterface.HealthcareDoc>).find(item => item.documentType.Name === docType);
        

    useEffect(() => {
        const getComprehendClassification = async () => {
            const { Entities } = await getDocumentAsJSON(docData![COMP_CLASSIFICATION_KEY]!);
            //console.log(Entities);
            // sort results by confidence score
            Entities?.sort((a: { Score: number; },b: { Score: number; }) => b.Score - a.Score);
            // filtering to only display medical conditions
            
            setMedicalConditions(Entities.filter((medCond: { Category: string; }) => medCond.Category === "MEDICAL_CONDITION").map((entity: any) => 
            //entity.Attributes.flatMap(concept: any) => []
            ({
                Text: entity.Text,
                Category: entity.Category,
                Score: entity.Score
            })))
            
            //console.log(MedicalConditions);
                //entity.RxNormConcepts.flatMap((concept: any) => [{ ...concept, Text: entity.Text }])).flat())
        }
        if (docType && docData && docData.documentType.Name === docType) {
            getComprehendClassification();
        }
    }, [docData, docType]);

    const toTitleCase = (text: string) => {
        return text
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    // table column definitions
    const columnDefinitions: TableProps.ColumnDefinition<MedicalConditionsTableProps>[] = [
        {
            id: 'Text',
            header: 'Condition',
            cell: (item: MedicalConditionsTableProps) => (toTitleCase(item.Text)),
            minWidth: 50,
        },
        {
            id: 'Confidence',
            header: 'Confidence',
            cell: (item: MedicalConditionsTableProps) => (`${(item.Score * 100).toFixed(2)}%`),
            minWidth: 180,
        },
        // {
        //     id: 'Description',
        //     header: 'Description',
        //     cell: (item: MedicalConditionsTableProps) => item.Description
        // },
        // {
        //     id: 'Score',
        //     header: 'Confidence',
        //     cell: (item: ICDTableProps) => (`${(item.Score * 100).toFixed(2)}%`)
        // }

    ]

    return (
        <div style={{ padding: 5, height: window.innerHeight * 0.8, overflow: 'auto', }}>
            {MedicalConditions ? <Table
                columnDefinitions={columnDefinitions}
                items={MedicalConditions}
                stickyHeader
                wrapLines
                variant="stacked"
            /> : <Spinner />}
        </div>
    )
}

export default MedicalConditions;