
/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useEffect, useState } from 'react';

// Polaris Components
import {
    Header,
    Box,
    Container,
    Link,
    Tiles,
    SpaceBetween,
    ColumnLayout
} from '@amzn/awsui-components-react';

//icons
import { AiOutlineFilePdf, AiOutlineFileImage, AiOutlineFileWord } from "react-icons/ai";

// interface
import { AppEnums, AppInterface, AppConfigFeatures } from '@aws-prototype/shared';

// Lightbox
import Lightbox from 'react-image-lightbox';

// utils
import { getDocumentPreviewUrl } from '../../utils';

// react query
import { useQueryClient } from "react-query";

// redux
import { useDispatch } from 'react-redux'

interface ClaimDocumentsProps {
    useCase: string
}


const ClaimDocuments = (props: ClaimDocumentsProps) => {
    const dispatch = useDispatch();
    // states
    const [value, setValue] = useState("");
    const [document, setDocument] = useState<AppInterface.ClaimDoc>()

    // lightbox states
    const [lightBox, setLightBox] = useState({
        show: false,
        imageUrl: 'https://a0.awsstatic.com/libra-css/images/logos/aws_logo_smile_1200x630.png',
    })

    //RQ cache
    const queryClient = useQueryClient();
    const data = (queryClient.getQueriesData("idp-insurance-docs")[0][1] as Array<AppInterface.ClaimDoc>);

    useEffect(() => {
        dispatch(AppConfigFeatures.setHelpKey(1));
        if (data) {
            setValue(data[0].documentType.Name);
            setDocument(data[0]);
        }
    }, [dispatch, data])

    const getFileType = (fileType: string) => {
        switch (fileType) {
            case AppEnums.ClaimFileType.doc:
                return <AiOutlineFileWord size={80} color={'#00a1c9'} />
            case AppEnums.ClaimFileType.jpg:
            case AppEnums.ClaimFileType.png:
                return <AiOutlineFileImage size={80} color={'#00a1c9'} />
            case AppEnums.ClaimFileType.pdf:
                return <AiOutlineFilePdf size={80} color={'#00a1c9'} />
        }
    }

    return (
        <SpaceBetween size="l">
            {lightBox.show && (<Lightbox
                reactModalStyle={{ overlay: { zIndex: 2000 } }}
                mainSrc={lightBox.imageUrl}
                onCloseRequest={() => setLightBox({ ...lightBox, show: false })}
            />)}

            <Container header={<Header variant="h2">
                Document List
            </Header>}>
                {(data) &&
                    <Tiles
                        value={value}
                        onChange={({ detail }) => {
                            setValue(detail.value);
                            setDocument(data.find((item: AppInterface.ClaimDoc) =>
                                item.documentType.Name === detail.value));
                        }}
                        columns={4}
                        items={data.map((item: AppInterface.ClaimDoc, index: number) => {
                            return ({
                                value: item.documentType.Name,
                                description: item.fileSize,
                                label: `Document ${index + 1}`,
                                image: getFileType(item.sortKey.split('.').pop()!),
                            })
                        })}
                    />}
            </Container>
            {
                value && document ?
                    <Container header={<Header variant="h2">
                        Document Details
                    </Header>}>
                        <ColumnLayout columns={2} variant="text-grid">
                            <SpaceBetween size="l" >
                                <div>
                                    <Box variant="awsui-key-label">Document Size</Box>
                                    <div>{document.fileSize}</div>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Created At</Box>
                                    <div>{document.uploadedAt}</div>
                                </div>
                            </SpaceBetween>
                            <SpaceBetween size="l">
                                <div>
                                    <Box variant="awsui-key-label">File Type</Box>
                                    <div>{document.sortKey.split('.').pop()!}</div>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Document Preview</Box>
                                    <Link
                                        external
                                        externalIconAriaLabel="Opens in a new tab"
                                        onFollow={async () => {
                                            setLightBox({
                                                show: true,
                                                imageUrl: await getDocumentPreviewUrl(document.uploadLocation)
                                            })
                                        }}
                                    >
                                        View
                                    </Link>
                                </div>
                            </SpaceBetween>
                        </ColumnLayout>
                    </Container> : null
            }
        </SpaceBetween>
    )
}

export default ClaimDocuments;