/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import A2i from '../../assets/a2i.png';
import Grid from "@amzn/awsui-components-react/polaris/grid";

const HumanReview = () => {
    return (
        <Grid gridDefinition={[{ colspan: 12 }]}>
            <img
                style={{ maxWidth: '100%' }}
                src={A2i}
                alt="A2i"
            />
            <p>Limit human review for documents with confidence scores less than a predefined target threshold or for specific key terms/values.</p>
        </Grid>
    )
}

export default HumanReview;