/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { Container, Header, Button, SpaceBetween } from "@amzn/awsui-components-react";

// interface
import { AppInterface, batchIdAtom } from '@aws-prototype/shared'
import { useAtom } from "jotai";


// router
import { useNavigate } from "react-router-dom";
import { routeNames } from "src/common/route";

const IndustryCard = ({ industry }: { industry: AppInterface.Industry }) => {
    const [, setBatchId] = useAtom(batchIdAtom)
    const navigate = useNavigate();

    return (
        <Container
            header={
                <Header variant="h3" actions={[
                <>
                            {industry.reportingHashKey ? <Button
            key={industry.name+"_reporting"}
            variant="normal"
            disabled={industry.sortKey === '1'}
            onClick={() =>{
                setBatchId(industry.reportingHashKey!)
                navigate(routeNames.Reporting)
            }
                }
        >
            {"Reporting"}
            
        </Button>: null}
                <Button
                    key={industry.name}
                    variant="primary"
                    disabled={industry.sortKey === '1'}
                    onClick={() =>
                        navigate(industry.name.toLowerCase())}
                >
                    {industry.actionTitle}
                </Button>

        </>
            ]}>
                    {industry.displayName || industry.name}
                </Header>
            }
            disableContentPaddings
        >
            <SpaceBetween size="xxxs">
                <div>
                    <img
                        height='500px'
                        width='100%'
                        alt={industry.name}
                        src={industry.image}
                    />
                </div>
                <p style={{ fontSize: '13px', padding: '5px 20px' }}>
                    {industry.description}
                </p>
            </SpaceBetween>
        </Container >
    );
}

export default IndustryCard;