/**********************************************************************************************************************
 *  Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
export enum Status {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
  STOPPED = 'stopped',
  PENDING = 'pending',
  IN_PROGRESS = 'in-progress',
  LOADING = 'loading',
}

export enum ClaimStates {
  PENDING_REVIEW = 'PENDING_REVIEW',
  AUTO_APPROVED = 'AUTO_APPROVED',
  AUTO_REJECTED = 'AUTO_REJECTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum ClaimDocTypes {
  CMS1500 = 'CMS1500',
  DISCHARGE_SUMMARY = 'DISCHARGE_SUMMARY',
  INSURANCE_ID = 'INSURANCE_ID',
  INVOICE_RECEIPT = 'INVOICE_RECEIPT',
  MEDICAL_TRANSCRIPTION = 'MEDICAL_TRANSCRIPTION',
  LICENSE = 'LICENSE',
  PASSPORT = 'PASSPORT',
  UNKNOWN = 'UNKNOWN',
  // URLA1003 = 'URLA1003',
  // W2 = 'W2',
  // PAY_STUB = 'PAY_STUB',
  // BANK_STMT = 'BANK_STMT',
  // MORTGAGE_NOTE = 'MORTGAGE_NOTE',
  // HOA_STMT = 'HOA_STMT'
}

export enum ClaimDocTypesValues {
  CMS1500 = 'CMS1500 Claim Form',
  DISCHARGE_SUMMARY = 'Discharge Summary',
  INSURANCE_ID = 'Insurance ID',
  INVOICE_RECEIPT = 'Invoice',
  MEDICAL_TRANSCRIPTION = 'Medical Transcription',
  LICENSE = 'Drivers License',
  PASSPORT = 'Passport',
  UNKNOWN = 'Unknown Type',
  // URLA1003 = 'Uniform Residential Loan Application',
  // W2 = 'W2',
  // PAY_STUB = 'Pay Stub',
  // BANK_STMT = 'Bank Statement',
  // MORTGAGE_NOTE = 'Mortgage Note',
  // HOA_STMT = 'HOA Statement'
}

export enum MortgageDocTypes {
  URLA1003 = 'URLA1003',
  W2 = 'W2',
  PAY_STUB = 'PAY_STUB',
  BANK_STMT = 'BANK_STMT',
  MORTGAGE_NOTE = 'MORTGAGE_NOTE',
  PASSPORT = 'PASSPORT',
  HOA_STMT = 'HOA_STMT',
  UNKNOWN = 'UNKNOWN',
}

export enum MortgageDocTypesValues {
  URLA1003 = 'Uniform Residential Loan Application',
  W2 = 'W2',
  PAY_STUB = 'Pay Stub',
  BANK_STMT = 'Bank Statement',
  MORTGAGE_NOTE = 'Mortgage Note',
  PASSPORT = 'Passport',
  HOA_STMT = 'HOA Statement',
  UNKNOWN = 'UNKNOWN',
}

export enum HealthcareDocTypes {
  CMS1500 = 'CMS1500',
  DISCHARGE_SUMMARY = 'DISCHARGE_SUMMARY',
  INSURANCE_ID = 'INSURANCE_ID',
  INVOICE_RECEIPT = 'INVOICE_RECEIPT',
  MEDICAL_TRANSCRIPTION = 'MEDICAL_TRANSCRIPTION',
  LICENSE = 'LICENSE',
  PRESCRIPTION = 'PRESCRIPTION',
  UNKNOWN = 'UNKNOWN',
  // URLA1003 = 'URLA1003',
  // W2 = 'W2',
  // PAY_cd STUB = 'PAY_STUB',
  // BANK_STMT = 'BANK_STMT',
  // MORTGAGE_NOTE = 'MORTGAGE_NOTE',
  // HOA_STMT = 'HOA_STMT'
}

export enum HealthcareDocTypesValues {
  CMS1500 = 'CMS1500 Claim Form',
  DISCHARGE_SUMMARY = 'Discharge Summary',
  INSURANCE_ID = 'Insurance ID',
  INVOICE_RECEIPT = 'Invoice',
  MEDICAL_TRANSCRIPTION = 'Medical Transcription',
  LICENSE = 'Drivers License',
  PRESCRIPTION = 'Prescription',
  UNKNOWN = 'Unknown Type',
  // URLA1003 = 'Uniform Residential Loan Application',
  // W2 = 'W2',
  // PAY_STUB = 'Pay Stub',
  // BANK_STMT = 'Bank Statement',
  // MORTGAGE_NOTE = 'Mortgage Note',
  // HOA_STMT = 'HOA Statement'
}

export enum ApplicationDocTypes {
  APPLICATION_FORM = 'APPLICATION_FORM',
  LICENSE = 'LICENSE',
  SSN_CARD = 'SSN_CARD',
  BANK_STMT = 'BANK_STMT',
  UTILITY_BILL = 'UTILITY_BILL',
  UNKNOWN = 'UNKNOWN',
  // URLA1003 = 'URLA1003',
  // W2 = 'W2',
  // PAY_STUB = 'PAY_STUB',
  // BANK_STMT = 'BANK_STMT',
  // MORTGAGE_NOTE = 'MORTGAGE_NOTE',
  // HOA_STMT = 'HOA_STMT'
}

export enum ApplicationDocTypesValues {
  APPLICATION_FORM = 'Benefit Application Form',
  SSN_CARD = 'Social Security Card',
  BANK_STMT = 'Bank Statement',
  LICENSE = 'Drivers License',
  UTILITY_BILL = 'Utility Bill',
  UNKNOWN = 'Unknown Type',
  // URLA1003 = 'Uniform Residential Loan Application',
  // W2 = 'W2',
  // PAY_STUB = 'Pay Stub',
  // BANK_STMT = 'Bank Statement',
  // MORTGAGE_NOTE = 'Mortgage Note',
  // HOA_STMT = 'HOA Statement'
}

// this could later be file MIME types
export enum ClaimFileType {
  pdf = 'pdf',
  jpg = 'jpg',
  png = 'png',
  doc = 'doc',
  xls = 'xls',
}

export enum ClaimFeatures {
  insurerID = 'insurerID',
  address = 'address',
  name = 'name',
  claimAmount = 'claimAmount',
}

export enum DocumentOutput {
  WORDS = 'WORDS',
  LINES = 'LINES',
  TABLES = 'TABLES',
  FORMS = 'FORMS',
  ID = 'ID',
  EXPENSE_LINE_ITEMS = 'EXPENSE_LINE_ITEMS',
  EXPENSE_SUMMARY_FIELDS = 'EXPENSE_SUMMARY_FIELDS',
  ENTITIES = 'ENTITIES',
  CUSTOM_ENTITIES = 'CUSTOM_ENTITIES',
  PII_ENTITIES = 'PII_ENTITIES',
  ICD10CM = 'ICD10CM',
}

export enum RuleStatus {
  PASS = 'PASS',
  FAIL = 'FAIL',
}

export enum RuleType {
  KEY_FIELD_EXTRACTED = 'KEY_FIELD_EXTRACTED',
  DOCUMENT_VERIFICATION = 'DOCUMENT_VERIFICATION',
  ICD10_VERIFICATION = 'ICD10_VERIFICATION',
}

export enum DeploymentRegion {
  DEPLOYMENT_REGION = 'us-west-2',
  PIPELINE_REGION = 'us-west-2', // note this will always be us-west-2 for the picapica replica
}
