/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
import { useState } from 'react'
import { SideNavigation } from '@amzn/awsui-components-react';

// nav items
import { navItems } from '../../common/route';

// router
import { useNavigate, useLocation } from "react-router-dom";

const PageNavigation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const [isAdmin] = useState(false);
    const [activeHref, setActiveHref] = useState(location.pathname);

    return (
        <SideNavigation                        
            // header={navHeader}
            activeHref={location.pathname || activeHref}
            items={isAdmin ? [...navItems] : navItems}
            onFollow={event => {
                if (!event.detail.external) {
                    event.preventDefault();
                    setActiveHref(event.detail.href);
                    navigate(event.detail.href);
                }
            }} />
    )
}

export default PageNavigation;