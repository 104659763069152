/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */
 import { HelpPanel, Icon } from "@amzn/awsui-components-react";

 const InsuranceHelp = () => {
     return (
         <HelpPanel
             footer={
                 <div>
                     <h3>
                         Learn more <Icon name="external" />
                     </h3>
                     <ul>
                         <li>
                             <a target="_blank" rel="noopener noreferrer nofollow" href="https://aws.amazon.com/machine-learning/ml-use-cases/document-processing/healthcare/">Automatically process healthcare documents using AI</a>
                         </li>
                         <li>
                             <a target="_blank" rel="noopener noreferrer nofollow" href="https://catalog.us-east-1.prod.workshops.aws/workshops/c2af04b2-54ab-4b3d-be73-c7dd39074b20/en-US">Quickly process insurance documents with AI</a>
                         </li>
                     </ul>
                 </div>
             }
             header={<h2>Mortgage</h2>}
         >
             <div>
                 Documents involved in a Mortgage application packet often have varied layouts and
                 formats which makes text extraction difficult.
                 Using machine learning, you can
                 extract relevant fields such as mortgage loan amount, APR, property address
                 from sections of a document or classify
                 documents with ease. By combining
                 text extraction and NLP, you can process
                 mortgage forms such as URLA 1003,
                 W2, Payslips, and mortgage notes
                 faster, with higher accuracy.
             </div>
         </HelpPanel>)
 }
 export default InsuranceHelp;