/** *******************************************************************************************************************
 *  Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *      http://aws.amazon.com/asl/                                                                                    *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 ******************************************************************************************************************** */

import { Routes, Route } from "react-router-dom";
import { routeNames, routes } from '../../common/route';

// Help components 
import HomeHelp from '../help-panel/HomeHelp';
import ClaimsHelp from '../help-panel/ClaimsHelp';
import MortgageHelp from '../help-panel/MortgageHelp';
import AnalyzeClaimHelp from '../help-panel/AnalyzeClaimHelp';
import AnalyzeMortgageHelp from "../help-panel/AnalyzeMortgageHelp";
import AnalyzeHealthCareHelp from "../help-panel/AnalyzeHealthCareHelp";
import HealthCareHelp from "../help-panel/HealthCareHelp";

const PageHelp = () => {
    return (
        <>
            <Routes>
                <Route path={routes[routeNames.Home].href} element={<HomeHelp />} />
                <Route path={routes[routeNames.Insurance].href} element={<ClaimsHelp />} />
                <Route path={routes[routeNames.Claim].href} element={<AnalyzeClaimHelp />} />
                <Route path={routes[routeNames.Mortgage].href} element={<MortgageHelp />} />
                <Route path={routes[routeNames.MtApplication].href} element={<AnalyzeMortgageHelp />} /> 
                <Route path={routes[routeNames.Healthcare].href} element={<HealthCareHelp />} /> 
                <Route path={routes[routeNames.HealthcareClaim].href} element={<AnalyzeHealthCareHelp />} /> 
            </Routes>
        </>
    );
}

export default PageHelp;