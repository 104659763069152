/**********************************************************************************************************************
 *  Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.                                           *
 *                                                                                                                    *
 *  Licensed under the Amazon Software License (the "License"). You may not use this file except in compliance        *
 *  with the License. A copy of the License is located at                                                             *
 *                                                                                                                    *
 *       http://aws.amazon.com/asl/                                                                                   *
 *                                                                                                                    *
 *  or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES *
 *  OR CONDITIONS OF ANY KIND, express or implied. See the License for the specific language governing permissions    *
 *  and limitations under the License.                                                                                *
 **********************************************************************************************************************/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppEnums } from '@aws-prototype/shared';

// a mock implementation to test redux slices
// we may retrieve these values from a DB instead of a file
 
 type docTypesEnumType = AppEnums.ClaimDocTypes | AppEnums.MortgageDocTypes | AppEnums.HealthcareDocTypes | AppEnums.ApplicationDocTypes

export interface ClaimClassifySliceType {
    // type: AppEnums.ClaimDocTypes,
    type: docTypesEnumType,
    label: string,
    value: string,
    confidence: string
}


export const claimClassifySlice = createSlice({
    name: 'claimClassify',
    initialState: [] as ClaimClassifySliceType[],
    reducers: {
        // add reducers based on app functionality
        add: (state, action: PayloadAction<ClaimClassifySliceType>) => {
            if (state.findIndex(item => item.type === action.payload.type) === -1) {
                state.push(action.payload);
            }
        },
        remove: (state, action: PayloadAction<ClaimClassifySliceType>) => {
            return state.filter(item => item.type !== action.payload.type)
        },
    },
});

export const { add, remove } = claimClassifySlice.actions; 
